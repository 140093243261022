import { memo } from 'react'
import {
    Image,
    ImageWrapper,
    MenuItemWrapper,
    NavBarWrapper,
    Label,
} from './styles'
import { assets } from '../../utils'
import { useLocation, useParams } from 'react-router-dom'
import { url } from '../../utils/constants'
import { colors } from '../../_principles'
import SpacerY from '../../components/Spacer/Y'

type Menu = {
    id: number
    href?: string
    icon: string
    iconActive: string
    isActive: boolean
    label: string
}

const BottomNav = memo(function BottomNav() {
    const { pathname } = useLocation()
    const { user_id } = useParams()

    const menuList: Menu[] = [
        {
            id: 1,
            // href: url.home,
            icon: assets.home,
            // TODO: change iconActive
            iconActive: assets.userAvatar,
            isActive: pathname === url.home,
            label: 'ホーム',
        },
        {
            id: 2,
            // href: `/${user_id}/posts`,
            icon: assets.postList,
            // TODO: change iconActive
            iconActive: assets.userAvatar,
            isActive: pathname === `/${user_id}${url.post}`,
            label: '投稿一覧',
        },
        {
            id: 3,
            href: url.mypage,
            icon: assets.userAvatar,
            iconActive: assets.mypageActive,
            isActive: pathname.includes(user_id!),
            label: 'マイページ',
        },
    ]

    return (
        <NavBarWrapper>
            {menuList.map((menu) => (
                <MenuItemWrapper key={menu.id} to={menu.href!}>
                    <ImageWrapper>
                        <Image
                            src={menu.isActive ? menu.iconActive : menu.icon}
                            alt="menu"
                            width={24}
                            height={24}
                        ></Image>
                    </ImageWrapper>
                    <Label
                        color={
                            menu.isActive
                                ? colors.blackNavigation
                                : colors.gray.neutral3
                        }
                    >
                        {menu.label}
                    </Label>
                </MenuItemWrapper>
            ))}
            <SpacerY height={28} />
        </NavBarWrapper>
    )
})

export default BottomNav
