import styled from 'styled-components'
import { colors } from '../../_principles'
import { CheckboxState } from '.'

type CheckboxProps = {
    label?: string
    $state: CheckboxState
    $left?: number
    $right?: number
    $bottom?: number
}

const borderColors: Record<CheckboxState, string> = {
    primary: colors.gray.neutral6,
    secondary: colors.blue.primary,
    disabled: colors.gray.neutral6,
}

const checkColors: Record<CheckboxState, string> = {
    primary: colors.white,
    secondary: colors.white,
    disabled: colors.gray.neutral6,
}

const labelColors: Record<CheckboxState, string> = {
    primary: colors.gray.neutral1,
    secondary: colors.gray.neutral1,
    disabled: colors.blackDisable,
}

const backgroundChecked: Record<CheckboxState, string> = {
    primary: colors.green.primary,
    secondary: colors.blue.primary,
    disabled: colors.gray.neutral3,
}

export const Input = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`

export const Checkmark = styled.span<CheckboxProps>`
    position: absolute;
    ${({ label, $bottom }) => {
        if (label) {
            return `top: 10px;`
        } else if ($bottom) {
            return `bottom: ${$bottom}px;`
        }
    }}
    ${({ $left, $right }) => {
        if ($left) {
            return `left: ${$left}px;`
        } else if ($right) {
            return `right: ${$right}px;`
        }
    }}
    height: 16px;
    width: 16px;
    background-color: ${colors.white};
    border-radius: 2px;
    border: 1px solid
        ${({ $state }) => borderColors[$state ? $state : 'primary']};

    &::after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 3px;
        width: 4px;
        height: 6px;
        border: solid
            ${({ $state }) => checkColors[$state ? $state : 'primary']};
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`

export const Label = styled.label<CheckboxProps>`
    display: block;
    min-width: 16px;
    min-height: 16px;
    position: relative;
    padding-top: ${({ label }) => (label ? '8px' : 0)};
    padding-left: ${({ label }) => (label ? '28px' : 0)};
    padding-right: ${({ label }) => (label ? '28px' : 0)};
    padding-bottom: ${({ label }) => (label ? '8px' : 0)};
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: ${({ $state }) => labelColors[$state ? $state : 'primary']};

    ${Input}:checked {
        & ~ ${Checkmark} {
            border: none;
            background: var(
                --unnamed,
                ${({ $state }) =>
                    backgroundChecked[$state ? $state : 'primary']}
            );
        }

        & ~ ${Checkmark}:after {
            display: block;
        }
    }
`
