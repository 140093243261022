import { createBrowserRouter } from 'react-router-dom'
import { url } from '../utils/constants'
import LoginCallback from '../pages/Auth/LoginCallback'
import Register from '../pages/Auth/Register'
import Top from '../pages/Auth/Top'
import Home from '../pages/Home'
import CommentCreate from '../pages/Comment/Create'
import CommentEdit from '../pages/Comment/Edit'
import ImagePreview from '../pages/ImagePreview'
import Mypage from '../pages/Mypage'
import MypageEdit from '../pages/Mypage/Edit'
import PostCreate from '../pages/Post/Create'
import PostDetail from '../pages/Post/Detail'
import PostEdit from '../pages/Post/Edit'
import Setting from '../pages/Setting'
import { logout } from './actions'
import { loader, loginLoader } from './loaders'
import { storage } from '../utils'

export const router = createBrowserRouter([
    // require auth
    {
        id: 'root',
        path: url.home,
        loader() {
            return { user: storage.getUser }
        },
        children: [
            {
                path: url.mypage,
                loader: loader,
                Component: Mypage,
            },
            {
                path: url.userEdit,
                loader: loader,
                Component: MypageEdit,
            },
            {
                path: url.setting,
                loader: loader,
                Component: Setting,
            },
            // {
            //     path: `/:user_id${url.post}`,
            // },
            {
                path: `${url.post}/new`,
                loader: loader,
                Component: PostCreate,
            },
            {
                path: `/:user_id${url.post}/:post_id`,
                loader: loader,
                Component: PostDetail,
            },
            {
                path: `/:user_id${url.post}/:post_id/edit`,
                loader: loader,
                Component: PostEdit,
            },
            {
                path: `/:user_id${url.post}/:post_id${url.comment}/new`,
                loader: loader,
                Component: CommentCreate,
            },
            {
                path: `/:user_id${url.post}/:post_id${url.comment}/:comment_id/edit`,
                loader: loader,
                Component: CommentEdit,
            },
            {
                path: `${url.imagePreview}/:image_id`,
                loader: loader,
                Component: ImagePreview,
            },
        ],
    },
    {
        path: url.top,
        loader: loginLoader,
        Component: Top,
    },
    {
        index: true,
        path: url.home,
        // TODO: delete loader
        loader: loader,
        Component: Home,
    },
    {
        path: url.loginCallback,
        loader: loginLoader,
        Component: LoginCallback,
    },
    {
        path: url.register,
        loader: loginLoader,
        Component: Register,
    },
    {
        path: url.logout,
        action: logout,
    },
])
