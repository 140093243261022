import { memo } from 'react'
import { Checkmark, Label, Input } from './styles'

type CheckboxType = 'checkbox' | 'radio'
export type CheckboxState = 'primary' | 'secondary' | 'disabled'
type CheckboxProps = {
    label?: string
    checked?: boolean
    disabled?: boolean
    checkboxType?: CheckboxType
    state?: CheckboxState
    left?: number
    right?: number
    bottom?: number
    onChange: () => void
} & React.ComponentPropsWithoutRef<'input'>

const Checkbox = memo(function Checkbox({
    label,
    checked,
    disabled = false,
    checkboxType = 'checkbox',
    state = 'primary',
    left,
    right,
    bottom,
    onChange,
    ...props
}: CheckboxProps) {
    return (
        <Label label={label} $state={state}>
            {label}
            <Input
                type={checkboxType}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                {...props}
            />
            <Checkmark
                label={label}
                $state={state}
                $left={left}
                $right={right}
                $bottom={bottom}
            />
        </Label>
    )
})

export default Checkbox
