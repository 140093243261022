import styled from 'styled-components'

type TagProps = {
    $height: number
    $fontSize: number
    $fontWeight: number
    $lineHeight: number
    $letterSpacing: number
    $color: string
    $backgroundColor: string
    $borderColor: string
    $borderRadius: number
}

export const Wrapper = styled.span<TagProps>`
    padding: 1px 8px;
    height: ${({ $height }) => $height}px;
    display: inline-flex;
    align-items: center;
    font-size: ${({ $fontSize }) => $fontSize}px;
    font-weight: ${({ $fontWeight }) => $fontWeight};
    line-height: ${({ $lineHeight }) => $lineHeight};
    letter-spacing: ${({ $letterSpacing }) => $letterSpacing}px;
    color: ${({ $color }) => $color};
    background: ${({ $backgroundColor }) => $backgroundColor};
    border: 1px solid ${({ $borderColor }) => $borderColor};
    border-radius: ${({ $borderRadius }) => `${$borderRadius}px`};
`
