import { memo, useEffect, useState } from 'react'
import { Form, FormikProps } from 'formik'
import { CountdownWrapper, FieldWrapper } from './styles'
import { fields, CommentForm } from '../../utils/constants'
import { colors } from '../../../../_principles'
import InputField from '../../../../components/InputField'
import SpacerY from '../../../../components/Spacer/Y'
import Text from '../../../../components/Text'

type Props = {
    setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
} & FormikProps<CommentForm>

const FormView = memo(function FormView({
    values,
    errors,
    touched,
    setFieldValue,
    setIsDisabled,
}: Props) {
    const [contentCountDown, setContentCountDown] = useState<number>(500)

    // フォームの変更を監視
    useEffect(() => {
        const { content } = values
        const contentLength = content.length

        setContentCountDown(500 - contentLength)
    }, [values])

    useEffect(() => {
        // エラーがある場合はボタンを無効化
        const hasError = Object.keys(errors).length > 0
        setIsDisabled(hasError)
    }, [errors])

    return (
        <Form>
            {/* 本文 */}
            <FieldWrapper>
                <InputField
                    type={fields[0].type}
                    name={fields[0].name}
                    placeholder={fields[0].placeholder}
                    fontSize={fields[0].fontSize}
                    autoFocus={fields[0].autoFocus}
                    paddingLeft={-12}
                    disableBorder
                    // errorMessage={errors[fields[0].name]}
                />
                <SpacerY height={24} />
                <CountdownWrapper>
                    <Text
                        color={
                            contentCountDown >= 0
                                ? colors.gray.neutral7
                                : colors.red.error
                        }
                        lineHeight={2}
                    >
                        {contentCountDown}
                    </Text>
                </CountdownWrapper>
            </FieldWrapper>
        </Form>
    )
})

export default FormView
