import { memo, useEffect, useState } from 'react'
import Icon from '../Icon'

type Props = {
    width?: number
    height?: number
    image: string | File
}

const Thumb = memo(function Thumb({ width, height, image }: Props) {
    const [loading, setLoading] = useState<boolean>(true)
    const [thumb, setThumb] = useState<string>('')
    useEffect(() => {
        if (typeof image === 'string') {
            setThumb(image)
            return
        }

        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onloadend = () => {
            setThumb(reader.result as string)
        }
        setLoading(false)
    }, [image])

    if (!image) return null

    return <Icon src={thumb} width={width} height={height} />
})

export default Thumb
