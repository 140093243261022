import styled from 'styled-components'
import { colors } from '../../_principles'
import { flexColumn } from '../../_principles/styles'

type SelectProps = {
    $height: number
    $backgroundColor: string
    $error: boolean
    $dirty: boolean
    $borderRadius: number
    $width?: number
    $hasOptionName?: boolean
    $isSelected?: boolean
    $hasBorderRight?: boolean
}

export const Icon = styled.img`
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
    z-index: 1;
`

export const Wrapper = styled.div<Pick<SelectProps, '$height'>>`
    height: ${({ $height }) => `${$height}px`};
    width: 100%;
    position: relative;
`

export const SelectButton = styled.button<SelectProps>`
    outline: none;
    width: 100%;
    height: ${(props) => `${props.$height}px`};
    position: relative;
    border-radius: ${(props) => `${props.$borderRadius}px`};
    border: 1px solid
        ${(props) =>
            props.$error ? colors.red.dustRed5 : colors.gray.neutral5};
    background-color: ${(props) => props.$backgroundColor};
    text-align: left;
    padding: 0 12px;
    color: ${(props) =>
        props.$dirty
            ? colors.characterTitle85
            : colors.characterDisabledPlaceholder25};
`

export const OptionItem = styled.button<
    Pick<SelectProps, '$isSelected' | '$hasBorderRight'>
>`
    text-align: left;
    width: 100%;
    background-color: ${({ $isSelected }) =>
        $isSelected ? colors.gray.neutral3 : 'transparent'};
    border: none;
    padding: 5px 10px;
    border-right: ${({ $hasBorderRight }) =>
        $hasBorderRight ? `1px solid ${colors.gray.neutral4}` : 'none'};
    cursor: pointer;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const OptionWrapper = styled.div<
    Pick<SelectProps, '$height' | '$width'>
>`
    ${flexColumn};
    width: ${(props) => (props.$width ? `${props.$width}px` : '100%')};
    position: absolute;
    top: ${(props) => `${props.$height - 2}px`};
    background-color: ${colors.white};
    z-index: 3;
    border-radius: 8px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid ${colors.gray.neutral4};
    max-height: 200px;
    overflow-y: auto;
`

export const HeaderCardOptions = styled.div`
    font-weight: 500;
    text-align: left;
    padding: 5px 10px;
    border-bottom: 1px solid #f0f0f0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const CardOptionsItem = styled.div``

export const CardOptions = styled.div<Pick<SelectProps, '$hasOptionName'>>`
    display: ${(props) => (props.$hasOptionName ? 'grid' : 'block')};
    grid-template-columns: 40% 60%;

    ${HeaderCardOptions} {
        &:first-child {
            border-right: 1px solid #f0f0f0;
        }
    }
`

export const Nodata = styled.div`
    text-align: center;
    line-height: 40px;
    height: 40px;
`
