import { useMemo, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import FormView from './components/Form'
import { FormWrapper } from './styles'
import {
    UserForm,
    ReqSchema,
    ResSchema,
    validationSchema,
} from './utils/constants'
import { ProfileWrapper, Container } from '../styles'
import Back from '../../../components/Back'
import Button from '../../../components/Button'
import SpacerY from '../../../components/Spacer/Y'
import useMutation from '../../../hooks/useMutation'
import Layout from '../../../layouts/Layout'
import { storage } from '../../../utils'
import { api, url } from '../../../utils/constants'
import ImageForm from './components/ImageForm'

export default function MypageEdit() {
    const user = storage.getUser()
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [initialValues, setInitialValues] = useState<UserForm | null>(null)
    const formikRef = useRef<FormikProps<UserForm>>(null)
    const { postAction } = useMutation<ReqSchema, ResSchema>({
        apiURL: `${api.auth}/update`,
    })

    useMemo(() => {
        if (!user) {
            navigate(url.top)
            return
        }

        setInitialValues({
            ['name']: user.name || '',
        })
    }, [])

    const handleSubmit = async (values: UserForm) => {
        if (!user) return
        if (!values) return

        const params = new FormData()
        params.append('user_id', user.id.toString())
        params.append('name', values.name)
        values.avatar &&
            params.append('avatar', values.avatar, values.avatar.name)
        values.cover_image &&
            params.append(
                'cover_image',
                values.cover_image,
                values.cover_image.name
            )

        const res = await postAction(params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        if (res.error) return
        if (!res.data) return
        storage.setUser(res.data.resources.user)
        toast.success(res.data.message)
        navigate(`/${user.user_id}`)
    }

    return (
        <Layout
            headerTitle="プロフィール編集"
            headerLeftEle={
                <Back
                    width={65}
                    height={25}
                    text={'キャンセル'}
                    onClick={() => navigate(-1)}
                />
            }
            headerRightEle={
                <Button
                    width={66}
                    height={25}
                    paddingY={4}
                    paddingX={8}
                    fontSize={10}
                    fontWeight={700}
                    disabled={isDisabled}
                    onClick={() => formikRef.current?.submitForm()}
                >
                    保存する
                </Button>
            }
            isVisibleBottomNav={false}
        >
            <Container>
                {user && initialValues && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        innerRef={formikRef}
                        onSubmit={handleSubmit}
                    >
                        {(props) => (
                            <>
                                <ProfileWrapper>
                                    <ImageForm
                                        user={user}
                                        setIsDisabled={setIsDisabled}
                                        {...props}
                                    />
                                </ProfileWrapper>
                                <SpacerY height={70} />
                                <FormWrapper>
                                    <SpacerY height={18} />
                                    <FormView
                                        user={user}
                                        setIsDisabled={setIsDisabled}
                                        {...props}
                                    />
                                </FormWrapper>
                            </>
                        )}
                    </Formik>
                )}
            </Container>
        </Layout>
    )
}
