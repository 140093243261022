import {
    BottomEleWrapper,
    FloatingWrapper,
    LayoutWrapper,
    Main,
} from './styles'
import BottomNav from '../BotttomNav'
import Header from '../Header'
import SpacerY from '../../components/Spacer/Y'
import Button from '../../components/Button'

type Props = {
    isVisibleHeader?: boolean
    headerTitle?: string
    headerLeftEle?: React.ReactNode
    headerRightEle?: React.ReactNode
    isHeaderBorderBottom?: boolean
    isVisibleBottomNav?: boolean
    bottomEle?: () => React.ReactNode
    floatingButtonIcon?: React.ReactElement
    floatingButtonAction?: () => void
    children: React.ReactNode
}

export default function Layout(props: Props) {
    const {
        isVisibleHeader = true,
        headerTitle = '',
        headerLeftEle = null,
        headerRightEle = null,
        isHeaderBorderBottom = false,
        isVisibleBottomNav = true,
        bottomEle,
        floatingButtonIcon,
        floatingButtonAction,
        children,
    } = props

    return (
        <LayoutWrapper>
            <SpacerY height={45} />
            {isVisibleHeader && (
                <Header
                    title={headerTitle}
                    leftEle={headerLeftEle}
                    rightEle={headerRightEle}
                    isBorderBottom={isHeaderBorderBottom}
                />
            )}
            <Main>{children}</Main>
            {floatingButtonIcon && (
                <FloatingWrapper>
                    <Button
                        onClick={floatingButtonAction}
                        width={70}
                        height={70}
                        paddingX={0}
                        paddingY={0}
                        icon={floatingButtonIcon}
                    />
                </FloatingWrapper>
            )}
            {bottomEle && <BottomEleWrapper>{bottomEle()}</BottomEleWrapper>}
            {isVisibleBottomNav && <BottomNav />}
        </LayoutWrapper>
    )
}
