import { memo } from 'react'
import { AvatarWrapper, TitleWrapper, Wrapper } from './styles'
import { User } from '../../utils/types'
import Avatar from '../Avatar'
import Title from '../Title'
import SubTitle from '../SubTitle'

type Props = {
    user: User
    isActiveLink?: boolean
}

const UserInfo = memo(function UserInfo({ user }: Props) {
    return (
        <Wrapper>
            <AvatarWrapper>
                <Avatar src={user.avatar} width={40} height={40} />
            </AvatarWrapper>
            <TitleWrapper>
                <Title fontSize={14}>{user.name}</Title>
                <br />
                <SubTitle fontSize={12}>@{user.user_id}</SubTitle>
            </TitleWrapper>
        </Wrapper>
    )
})

export default UserInfo
