import { memo } from 'react'
import { Input } from './styles'

type Props = {
    name: string
    placeholder?: string
    width?: number
    color?: string
    backgroundColor?: string
    borderColor?: string
    borderRadius?: number
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchField = memo(function SearchField({
    name,
    placeholder,
    width,
    color,
    backgroundColor,
    borderColor,
    borderRadius,
    onChange,
}: Props) {
    return (
        <Input
            name={name}
            placeholder={placeholder}
            $width={width}
            $color={color}
            $backgroundColor={backgroundColor}
            $borderColor={borderColor}
            $borderRadius={borderRadius}
            onChange={onChange}
        />
    )
})

export default SearchField
