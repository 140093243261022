import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
    AvatarWrapper,
    Container,
    CoverWrapper,
    MenuWrapper,
    ProfileWrapper,
    TitleWrapper,
    Wrapper,
} from './styles'
import {
    DeleteReqSchema,
    DeleteResSchema,
    SearchReqSchema,
    SearchResSchema,
} from './utils/constants'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Card from '../../components/Card'
import CoverImage from '../../components/CoverImage'
import Icon from '../../components/Icon'
import SubTitle from '../../components/SubTitle'
import Title from '../../components/Title'
import useMutation from '../../hooks/useMutation'
import Layout from '../../layouts/Layout'
import { assets, storage } from '../../utils'
import { api, url } from '../../utils/constants'
import { Post } from '../../utils/types'

export default function Mypage() {
    const user = storage.getUser()
    const { user_id } = useParams()
    const [page, setPage] = useState<number>(1)
    const [posts, setPosts] = useState<Post[]>([])
    const [isOpenActionModal, setIsOpenActionModal] = useState<boolean>(false)
    const navigate = useNavigate()
    const { postAction } = useMutation<SearchReqSchema, SearchResSchema>({
        apiURL: `${api.post}/search`,
    })
    const { postAction: deleteAction } = useMutation<
        DeleteReqSchema,
        DeleteResSchema
    >({
        apiURL: `${api.post}/delete`,
    })

    useEffect(() => {
        if (!user) return
        if (user_id !== user.user_id) {
            navigate(`/${user.user_id}`)
        }
    }, [user])

    useEffect(() => {
        const fetchData = async () => {
            const res = await postAction({ user_id: user?.id, page: page })
            if (res.error) return
            if (!res.data) return

            setPosts(res.data.resources.posts)
        }

        fetchData()
    }, [page])

    // 編集画面へ遷移
    const handleEdit = (id: number) => {
        if (!user) return
        navigate(`/${user.user_id}/post/${id}/edit`)
    }

    // 削除処理
    const handleDelete = async (id: number) => {
        const params = {
            ['post_id']: id,
        }
        const res = await deleteAction(params)
        if (res.error) return
        if (!res.data) return

        toast.success(res.data.message)
        setPosts(posts.filter((post) => post.id !== id))
    }

    return (
        <Layout
            isVisibleHeader={false}
            floatingButtonIcon={
                <Icon
                    src={assets.addPost}
                    alt="Mokusy Post"
                    width={70}
                    height={70}
                />
            }
            floatingButtonAction={() => navigate(`${url.post}/new`)}
        >
            {user && (
                <Wrapper>
                    <Container>
                        <ProfileWrapper>
                            <CoverWrapper>
                                <CoverImage src={user.cover_image} />
                            </CoverWrapper>
                            <AvatarWrapper>
                                <Avatar src={user.avatar} />
                            </AvatarWrapper>
                            <MenuWrapper>
                                <Button
                                    onClick={() => navigate(url.setting)}
                                    width={14.72}
                                    height={14.72}
                                    paddingX={0}
                                    paddingY={0}
                                    icon={
                                        <Icon
                                            src={assets.setting}
                                            width={14.72}
                                            height={14.72}
                                        />
                                    }
                                />
                                <Button
                                    width={125}
                                    height={25}
                                    fontSize={10}
                                    onClick={() => navigate('edit')}
                                    isOutline
                                >
                                    プロフィールを編集
                                </Button>
                            </MenuWrapper>
                            <TitleWrapper>
                                <Title>{user.name}</Title>
                                <br />
                                <SubTitle>@{user.user_id}</SubTitle>
                            </TitleWrapper>
                        </ProfileWrapper>
                    </Container>
                    {posts.map((post, index) => (
                        <Card
                            key={index}
                            link={`/${user.user_id}/post/${post.id}`}
                            id={post.id}
                            title={post.title}
                            content={post.content}
                            flavors={post.flavors}
                            images={post.images}
                            postedAt={post.posted_at}
                            user={post.user}
                            commentCount={post.comments.length}
                            onEdit={() => handleEdit(post.id)}
                            onDelete={() => handleDelete(post.id)}
                        />
                    ))}
                </Wrapper>
            )}
        </Layout>
    )
}
