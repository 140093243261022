import * as Yup from 'yup'
import { Comment, InputType, Post } from '../../../utils/types'

export const validationSchema = Yup.object().shape({
    ['content']: Yup.string()
        .required('本文を入力してください')
        .min(1, '1文字以上で入力してください')
        .max(500, '500文字以内で入力してください'),
})

export interface CommentForm extends Yup.InferType<typeof validationSchema> {}

export type FieldItem = {
    type: InputType
    name: keyof CommentForm
    label: string
    placeholder?: string
    fontSize?: number
    autoFocus?: boolean
    options?: { value: string; label: string }[]
    multiple?: boolean
}

export const fields: FieldItem[] = [
    {
        type: 'textarea',
        name: 'content',
        label: '本文',
        placeholder: '返信を入力',
        fontSize: 16,
    },
]

export type PostDetailResSchema = {
    ['post']: Post
}

export type DetailResSchema = {
    comment: Comment
}

export type CreateReqSchema = {
    ['post_id']: number
} & CommentForm

export type CreateResSchema = {
    ['comment']: Comment
}

export type UpdateReqSchema = {
    ['comment_id']: number
} & CommentForm

export type UpdateResSchema = {}
