import { memo } from 'react'
import { Field } from 'formik'
import { TextareaWrapper } from './styles'
import { colors } from '../../_principles'
import { FormikFieldType } from '../../utils/types'

type Props = {
    name: string
    maxLength?: number
    placeholder?: string
    width?: number
    height?: number
    error?: boolean
    fontSize?: number
    fontWeight?: number
    lineHeight?: number
    backgroundColor?: string
    paddingTop?: number
    paddingBottom?: number
    paddingLeft?: number
    paddingRight?: number
    borderRadius?: number
    readOnly?: boolean
    autoFocus?: boolean
    placeholderTop?: number
    textAlign?: string
    disableInput?: boolean
    disableBorder?: boolean
    rightIcon?: React.ReactNode
    onSetNameInput?: React.Dispatch<React.SetStateAction<string | null>>
} & React.ComponentPropsWithoutRef<'textarea'>

const Textarea = memo(function Textarea({
    name,
    maxLength = 500,
    placeholder,
    width,
    height = 370,
    error = false,
    fontSize = 16,
    fontWeight = 500,
    lineHeight = 24,
    backgroundColor = colors.white,
    paddingTop = 8,
    paddingBottom = 8,
    paddingLeft = 12,
    paddingRight = 12,
    borderRadius = 2,
    readOnly = false,
    autoFocus = false,
    placeholderTop = 0,
    textAlign = 'left',
    onSetNameInput,
    disableInput,
    disableBorder = false,
    rightIcon,
    ...props
}: Props) {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (readOnly) {
            if (event.key === 'Tab' || event.key === 'Enter') {
                event.preventDefault()
            }
        }
        if (disableInput) {
            if (event.key === 'Tab') {
                event.preventDefault()
            }
        }
        if (event.key === 'Enter') {
            const inputElement = event.target as HTMLInputElement
            onSetNameInput && onSetNameInput(inputElement.name)
        }
    }

    return (
        <Field name={name}>
            {({ field }: FormikFieldType) => (
                <TextareaWrapper
                    $width={width}
                    $height={height}
                    $error={error}
                    $borderRadius={borderRadius}
                    $backgroundColor={backgroundColor}
                    $disableBorder={disableBorder}
                    $fontSize={fontSize}
                    $fontWeight={fontWeight}
                    $lineHeight={lineHeight}
                    $paddingTop={paddingTop}
                    $paddingBottom={paddingBottom}
                    $paddingLeft={paddingLeft}
                    $paddingRight={paddingRight}
                    $placeholderTop={placeholderTop}
                    $textAlign={textAlign}
                >
                    <textarea
                        placeholder={placeholder}
                        {...field}
                        {...props}
                        autoFocus={autoFocus}
                        maxLength={maxLength}
                        readOnly={readOnly}
                        disabled={readOnly || disableInput}
                        // onFocus={() => {
                        //     onSetNameInput && onSetNameInput(null)
                        // }}
                    />
                    {rightIcon && rightIcon}
                </TextareaWrapper>
            )}
        </Field>
    )
})

export default Textarea
