import styled from 'styled-components'
import { colors, styles } from '../../_principles'

export const InputFieldWrapper = styled.div<{
    $width?: number
    $marginBottom: number
    $spacing: number
}>`
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    ${styles.flexColumn};
    gap: ${({ $spacing }) => `${$spacing}px`};
    margin-bottom: ${({ $marginBottom }) => `${$marginBottom}px`};
`

export const Label = styled.label<{ $color: string }>`
    line-height: 22px;
    color: ${({ $color }) => $color};
    font-weight: 700;
`

export const Error = styled.div`
    color: ${colors.red.dustRed5};
    font-size: 12px;
`

export const LabelWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: fit-content;
    align-items: center;
`

export const RequiredField = styled.div`
    color: ${colors.red.dustRed8};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: fit-content;
    ${styles.flexCenter};
    padding: 1px 2px;
    border: 1px solid ${colors.red.dustRed8};
    margin-left: 4px;
`
