import { useEffect, useMemo, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TextWrapper } from './styles'
import FormView from '../components/Form'
import FormLayout from '../components/Layout'
import SpacerY from '../../../components/Spacer/Y'
import Text from '../../../components/Text'
import {
    CommentForm,
    DetailResSchema,
    UpdateReqSchema,
    UpdateResSchema,
    validationSchema,
} from '../utils/constants'
import useMutation from '../../../hooks/useMutation'
import useQuery from '../../../hooks/useQuery'
import { colors } from '../../../_principles'
import { storage } from '../../../utils'
import { api, url } from '../../../utils/constants'

export default function CommentEdit() {
    const navigate = useNavigate()
    const user = storage.getUser()
    const { user_id, post_id, comment_id } = useParams()
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [initialValues, setInitialValues] = useState<CommentForm | null>(null)
    const formikRef = useRef<FormikProps<CommentForm> | null>(null)
    const { data, error } = useQuery<DetailResSchema>({
        apiURL: `/api/comment/${comment_id}`,
    })
    const { postAction } = useMutation<UpdateReqSchema, UpdateResSchema>({
        apiURL: `${api.comment}/update`,
    })

    useEffect(() => {
        if (!user) {
            toast.error('ログインしてください')
            navigate(url.top)
            return
        }

        // fix route to 404
        if (!user_id || !post_id || !comment_id) {
            toast.error('投稿が見つかりません')
            navigate(-1)
            return
        }

        if (isNaN(Number(post_id)) || isNaN(Number(comment_id))) {
            toast.error('投稿が見つかりません')
            navigate(-1)
            return
        }

        if (user_id !== user.user_id) {
            toast.error('編集権限がありません')
            navigate(-1)
            return
        }
    }, [])

    const { comment } = useMemo(() => {
        if (error) return { comment: null }
        if (!data) return { comment: null }
        return { comment: data.resources.comment }
    }, [data])

    useEffect(() => {
        if (!comment) return

        const initialValues: CommentForm = {
            ['content']: comment.content,
        }
        setInitialValues(initialValues)
    }, [comment])

    const handleSubmit = async (values: CommentForm) => {
        if (!user) return null
        if (!post_id) return null
        if (!comment_id) return null
        if (!comment) return null

        const params = new FormData()
        params.append('post_id', post_id)
        params.append('comment_id', comment_id)
        params.append('content', values.content)

        const res = await postAction(params)
        if (res.error) return
        if (!res.data) return
        toast.success(res.data.message)
        navigate(`/${user.user_id}/post/${post_id}`)
    }

    return (
        <FormLayout isEdit isDisabled={isDisabled} formikRef={formikRef}>
            <SpacerY height={12} />
            <TextWrapper>
                <Text fontSize={10} lineHeight={1.5}>
                    返信先:
                </Text>
                <Text
                    fontSize={10}
                    lineHeight={1.5}
                    color={colors.green.primary}
                >
                    {user_id}
                </Text>
            </TextWrapper>
            <SpacerY height={8} />
            {initialValues && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formikRef}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <FormView setIsDisabled={setIsDisabled} {...props} />
                    )}
                </Formik>
            )}
        </FormLayout>
    )
}
