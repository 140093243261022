import styled from 'styled-components'

export const IconWrapper = styled.div<{
    $top?: number
    $left?: number
}>`
    position: absolute;

    top: ${({ $top }) => ($top ? `${$top}%` : '30%')};
    left: ${({ $left }) => ($left ? `${$left}%` : '45%')};
    z-index: 10;
`
