import { css } from 'styled-components'
import colors from './colors'

export const errorText = css`
    margin-top: 10px;
    color: ${colors.black};
    font-weight: 500;
    font-size: 14px;
`

export const flexAlignCenter = css`
    margin-top: 10px;
    color: ${colors.black};
    font-weight: 500;
    font-size: 14px;
`

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const flexColumn = css`
    display: flex;
    flex-direction: column;
`

export const flexColumnAlignCenter = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const flexJustifyBetween = css`
    display: flex;
    justify-content: space-between;
`

export const flexJustifyBetweenAlignCenter = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const boxShadow = '0px 0px 24px 0px rgba(0, 0, 0, 0.12)'
