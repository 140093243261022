import { memo, useEffect } from 'react'
import { FormikProps } from 'formik'
import { useNavigate } from 'react-router-dom'
import { FieldWrapper, RightIcon } from './styles'
import { CommentForm, fields } from '../../../Comment/utils/constants'
import { colors } from '../../../../_principles'
import Textarea from '../../../../components/Textarea'
import { assets } from '../../../../utils'
import { User } from '../../../../utils/types'

type Props = {
    user: User
    postID: string
    isFocus: boolean
    setIsFocus: React.Dispatch<React.SetStateAction<boolean>>
    setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
} & FormikProps<CommentForm>

const FormView = memo(function FormView({
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    user,
    postID,
    isFocus,
    setIsFocus,
    setIsDisabled,
}: Props) {
    const navigate = useNavigate()

    useEffect(() => {
        if (values.content.length === 0) {
            setIsDisabled(true)
            return
        }
        setIsDisabled(false)
    })

    return (
        <FieldWrapper>
            <Textarea
                name={fields[0].name}
                value={values.content}
                error={!!errors.content}
                rows={1}
                placeholder={fields[0].placeholder}
                autoFocus={fields[0].autoFocus}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setFieldValue(fields[0].name, e.target.value)
                }
                onFocus={() => setIsFocus(true)}
                // onBlur={() => setIsFocus(false)}
                width={isFocus ? 223 : 287}
                height={37}
                fontSize={fields[0].fontSize}
                borderRadius={100}
                backgroundColor={colors.gray.neutral4}
                disableBorder
                rightIcon={
                    isFocus && (
                        <RightIcon
                            src={assets.panZoom}
                            alt="panZoom"
                            width={16}
                            onClick={() =>
                                navigate(
                                    `/${user.user_id}/post/${postID}/comment/new`
                                )
                            }
                        ></RightIcon>
                    )
                }
            />
        </FieldWrapper>
    )
})

export default FormView
