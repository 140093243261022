import axios from 'axios'
import { baseAPIURL } from './constants'

/**
 * axiosインスタンス
 */
const instance = axios.create({
    baseURL: baseAPIURL,
    headers: {
        ['Access-Control-Allow-Origin']: '*',
        ['Content-Type']: 'application/json',
        Accept: 'application/json',
    },
    withCredentials: true,
})

export default instance
