import styled from 'styled-components'

type LogoProps = {
    width: number
    height: number
}

export const Wrapper = styled.img<LogoProps>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
`
