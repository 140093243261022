import React, { memo } from 'react'
import { Wrapper } from './styles'
import { assets } from '../../utils'

type Props = {
    width?: number
    height?: number
    src?: string
    canEdit?: boolean
} & React.ComponentPropsWithoutRef<'img'>

const Avatar = memo(function Avatar(props: Props) {
    const { width = 67, height = 67, src, canEdit, ...otherProps } = props

    return (
        <Wrapper
            {...otherProps}
            $canEdit={canEdit}
            width={width}
            height={height}
            src={src ? src : assets.userAvatar}
        ></Wrapper>
    )
})

export default Avatar
