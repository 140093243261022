import { colors } from '../../_principles'
import { InputType, Options } from '../../utils/types'
import Input from '../Input'
import Select from '../Select'
import Textarea from '../Textarea'
import {
    Error,
    InputFieldWrapper,
    Label,
    LabelWrapper,
    RequiredField,
} from './styles'

type Props = {
    name: string
    placeholder?: string
    label?: string
    labelColor?: string
    error?: boolean
    errorMessage?: string
    marginBottom?: number
    type?: InputType
    spacing?: number
    paddingLeft?: number
    paddingRight?: number
    inputHeight?: number
    fontSize?: number
    fontWeight?: number
    lineHeight?: number
    options?: Options[]
    setFieldTouched?: (field: string, isTouched: boolean) => void
    setFieldValue?: (field: string, value: any) => void
    value?: any
    inputColor?: string
    inputBackgroundColor?: string
    readOnly?: boolean
    autoFocus?: boolean
    isRequired?: boolean
    width?: number
    disableBorder?: boolean
    multiple?: boolean
}

function InputField({
    name,
    placeholder,
    label,
    labelColor = colors.green.primary,
    error = false,
    errorMessage,
    marginBottom = 0,
    type = 'text',
    spacing = 4,
    paddingLeft,
    paddingRight,
    inputHeight,
    fontSize,
    fontWeight,
    lineHeight,
    options,
    setFieldTouched,
    setFieldValue,
    value,
    inputColor,
    inputBackgroundColor = colors.white,
    readOnly = false,
    autoFocus = false,
    isRequired = false,
    width,
    disableBorder = false,
    multiple = false,
}: Props) {
    const renderInput = () => {
        if (type === 'select' && options) {
            return (
                <Select
                    options={options}
                    name={name}
                    placeholder={placeholder}
                    height={inputHeight}
                    error={error}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    value={value}
                    borderRadius={0}
                    // optionsName={optionsName}
                    // nameLabels={nameLabels}
                />
            )
        }
        if (options) {
            return (
                <Select
                    options={options}
                    name={name}
                    placeholder={placeholder}
                    height={inputHeight}
                    error={error}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    value={value}
                    borderRadius={0}
                    // optionsName={optionsName}
                    // nameLabels={nameLabels}
                />
            )
        }
        if (type === 'textarea') {
            return (
                <Textarea
                    name={name}
                    placeholder={placeholder}
                    error={error}
                    height={inputHeight}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    lineHeight={lineHeight}
                    backgroundColor={inputBackgroundColor}
                    readOnly={readOnly}
                    autoFocus={autoFocus}
                    disableBorder={disableBorder}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                />
            )
        }
        return (
            <Input
                name={name}
                placeholder={placeholder}
                error={error}
                type={type}
                height={inputHeight}
                fontSize={fontSize}
                fontWeight={fontWeight}
                lineHeight={lineHeight}
                color={inputColor}
                backgroundColor={inputBackgroundColor}
                readOnly={readOnly}
                autoFocus={autoFocus}
                disableBorder={disableBorder}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
            />
        )
    }
    return (
        <InputFieldWrapper
            $width={width}
            $marginBottom={marginBottom}
            $spacing={spacing}
        >
            {label && (
                <LabelWrapper>
                    <Label $color={labelColor}>{label}</Label>
                    {isRequired && <RequiredField>必須</RequiredField>}
                </LabelWrapper>
            )}
            {renderInput()}
            {error && errorMessage ? <Error>{errorMessage}</Error> : null}
        </InputFieldWrapper>
    )
}

export default InputField
