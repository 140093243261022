import { useEffect } from 'react'
import { FormikProps } from 'formik'
import { FieldWrapper } from './styles'
import {
    checkExistsReqSchema,
    checkExistsResSchema,
    Form,
} from '../../utils/constants'
import { colors } from '../../../../_principles'
import InputField from '../../../../components/InputField'
import useMutation from '../../../../hooks/useMutation'
import { userIDRegex } from '../../../../utils/constants'
import { User } from '../../../../utils/types'

type Props = {
    user: User
    setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
} & FormikProps<Form>

function FormView({
    errors,
    touched,
    values,
    setFieldError,
    user,
    setIsDisabled,
}: Props) {
    const { user_id } = values
    const { postAction } = useMutation<
        checkExistsReqSchema,
        checkExistsResSchema
    >({ apiURL: `/api/${user_id}/check-exists` })

    useEffect(() => {
        // ユーザーIDが変更されたら重複チェック
        const checkUserID = async () => {
            const res = await postAction()

            if (res.error) {
                setFieldError('user_id', res.error.message)
                setIsDisabled(true)
                return
            }
            if (!res.data) return

            if (res.data.resources.is_exists) {
                setFieldError('user_id', res.data.message)
                setIsDisabled(true)
            } else {
                setFieldError('user_id', undefined)
            }
        }

        if (user.user_id === user_id) return

        // ユーザーIDが正規表現にマッチしたら重複チェック
        if (userIDRegex.test(user_id)) {
            checkUserID()
        }
    }, [user_id])

    // ボタンの状態を更新
    useEffect(() => {
        if (user.user_id === user_id) {
            setIsDisabled(true)
            return
        }
        if (!userIDRegex.test(user_id)) {
            setIsDisabled(true)
            return
        }

        setIsDisabled(errors.user_id != undefined)
    }, [user_id])

    return (
        <FieldWrapper $width={339}>
            <InputField
                type="text"
                name="user_id"
                label="ユーザーID"
                labelColor={colors.black}
                placeholder="ユーザーID(5~15文字)"
                fontSize={13}
                autoFocus
                width={311}
                error={errors.user_id != undefined && touched.user_id}
                errorMessage={errors.user_id}
            />
        </FieldWrapper>
    )
}

export default FormView
