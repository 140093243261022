import { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionButton, Wrapper } from './styles'
import Modal from '../Modal'
import ConfirmDeleteModal from '../ConfirmDeleteModal'

type Props = {
    editLabel: string
    deleteLabel: string
    onEdit: () => void
    onDelete: () => void
}

const ActionCard = memo(function ActionCard({
    editLabel,
    deleteLabel,
    onEdit,
    onDelete,
}: Props) {
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
    const navigate = useNavigate()

    const handleEdit = () => {
        onEdit()
    }

    const handleDeleteModal = () => {
        setIsOpenModal((prev) => !prev)
    }

    const handleDeleteConfirm = () => {
        onDelete()
        setIsOpenModal(false)
    }

    const handleDeleteCancel = () => {
        setIsOpenModal(false)
    }

    return (
        <>
            <Wrapper>
                <ActionButton onClick={handleEdit}>{editLabel}</ActionButton>
                <ActionButton $isDelete onClick={handleDeleteModal}>
                    {deleteLabel}
                </ActionButton>
            </Wrapper>
            <Modal
                isOpen={isOpenModal}
                closeModal={() => setIsOpenModal(false)}
            >
                <ConfirmDeleteModal
                    title="投稿を削除"
                    description="この投稿を削除してもよろしいですか？"
                    confirmLabel="削除"
                    cancelLabel="キャンセル"
                    onConfirm={handleDeleteConfirm}
                    onCancel={handleDeleteCancel}
                />
            </Modal>
        </>
    )
})

export default ActionCard
