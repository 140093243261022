import { memo } from 'react'
import {
    BottomButton,
    BottomButtonWrapper,
    TextWrapper,
    TitleWrapper,
    Wrapper,
} from './styles'
import SpacerY from '../Spacer/Y'
import Text from '../Text'
import Title from '../Title'
import { colors } from '../../_principles'

type Props = {
    title: string
    description: string
    confirmLabel: string
    cancelLabel: string
    onConfirm: () => void
    onCancel: () => void
}

const ConfirmDeleteModal = memo(function ConfirmDeleteModal({
    title,
    description,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel,
}: Props) {
    return (
        <Wrapper>
            <SpacerY height={16} />
            <TitleWrapper>
                <Title>{title}</Title>
            </TitleWrapper>
            <SpacerY height={4} />
            <TextWrapper>
                <Text color={colors.black}>{description}</Text>
            </TextWrapper>
            <SpacerY height={16} />
            <BottomButtonWrapper>
                <BottomButton onClick={onCancel}>{cancelLabel}</BottomButton>
                <BottomButton $isDelete onClick={onConfirm}>
                    {confirmLabel}
                </BottomButton>
            </BottomButtonWrapper>
        </Wrapper>
    )
})

export default ConfirmDeleteModal
