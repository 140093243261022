import { AxiosError } from 'axios'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import instance from '../utils/axios'
import { handleError } from '../utils/functions'
import { APIResponse } from '../utils/types'

type Props = {
    apiURL: string
}

/**
 * ミューテーションフック
 *
 * @param   apiURL API URL
 * @returns ローディングフラグ、POSTリクエスト、PUTリクエスト
 */
function useMutation<ReqSchema = unknown, ResSchema = unknown>({
    apiURL,
}: Props) {
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    /**
     * POSTリクエスト
     *
     * @param   req リクエストデータ
     * @param   headers ヘッダー
     * @returns レスポンス
     */
    const postAction = async (req?: ReqSchema | FormData, headers?: any) => {
        const response: {
            data: APIResponse<ResSchema> | null
            error: AxiosError<any> | null
        } = {
            data: null,
            error: null,
        }

        setLoading(true)

        try {
            const res = await instance.post(apiURL, req, headers)

            // ステータスコードが200以外の場合はエラー
            if (res.data.status !== 200) {
                throw new AxiosError(res.data.message, res.data.status)
            }
            response.data = res.data
        } catch (e) {
            // エラーハンドリング
            const error = e as AxiosError

            handleError(error, navigate, location)
            response.error = error
        } finally {
            setLoading(false)
        }

        return response
    }

    /**
     * PUTリクエスト
     *
     * @param   data リクエストデータ
     * @param   headers ヘッダー
     *
     * @returns レスポンス
     */
    const putAction = async (req: ReqSchema | FormData, headers?: any) => {
        const response: {
            data: APIResponse<ResSchema> | null
            error: AxiosError<any> | null
        } = {
            data: null,
            error: null,
        }

        setLoading(true)

        try {
            const res = await instance.put(apiURL, req, headers)
            // ステータスコードが200以外の場合はエラー
            if (res.data.status !== 200) {
                throw new AxiosError(res.data.message, res.data.status)
            }
            response.data = res.data
        } catch (e) {
            // エラーハンドリング
            const error = e as AxiosError

            handleError(error, navigate, location)
            response.error = error
        } finally {
            setLoading(false)
        }

        return response
    }

    return {
        loading,
        postAction,
        putAction,
    }
}

export default useMutation
