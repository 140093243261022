import styled, { css } from 'styled-components'
import { Props } from '.'
import { ButtonType } from '../../utils/types'
import { colors, styles } from '../../_principles'

type ButtonProps = Pick<Props, 'height'> & {
    $borderRadius: number
    $btnType: ButtonType
    $fontSize: number
    $fontWeight: number
    $lineHeight: number
    $isOutline?: boolean
    $isOnlyIcon?: boolean
    $width?: number
    $paddingY: number
    $paddingX: number
}

const backgroundColors: Record<ButtonType, string> = {
    primary: colors.green.primary,
    secondary: colors.gray.neutral4,
    linear: `linear-gradient(97deg, ${colors.green.fadedPoster} 11.06%, ${colors.blue.blessedBlue} 98.02%)`,
    transparent: 'transparent',
    error: colors.red.error,
    success: colors.blue.blessedBlue,
    neutral: colors.gray.neutral3,
}

const textColors: Record<ButtonType, string> = {
    primary: colors.white,
    secondary: colors.characterTitle85,
    linear: colors.white,
    transparent: colors.characterTitle85,
    error: colors.white,
    success: colors.white,
    neutral: colors.black,
}

const textColorsOutline: Record<ButtonType, string> = {
    primary: colors.green.primary,
    secondary: colors.characterTitle85,
    linear: colors.green.fadedPoster,
    transparent: colors.characterTitle85,
    error: colors.red.error,
    success: colors.blue.blessedBlue,
    neutral: colors.black,
}

const borderColors: Record<ButtonType, string> = {
    primary: colors.green.primary,
    secondary: colors.gray.neutral4,
    linear: colors.green.fadedPoster,
    transparent: 'transparent',
    error: colors.gray.neutral6,
    success: colors.gray.neutral6,
    neutral: colors.gray.neutral6,
}

const borderColorsOutline: Record<ButtonType, string> = {
    primary: colors.green.primary,
    secondary: colors.gray.neutral4,
    linear: colors.green.fadedPoster,
    transparent: 'transparent',
    error: colors.red.error,
    success: colors.blue.blessedBlue,
    neutral: colors.gray.neutral6,
}

export const Wrapper = styled.button<ButtonProps>`
    height: ${({ height }) => `${height}px`};
    width: ${({ $width }) => `${$width}px` || 'max-content'};
    ${styles.flexCenter};
    gap: 10px;
    padding: ${({ $paddingY, $paddingX }) => `${$paddingY}px ${$paddingX}px`};
    font-size: ${({ $fontSize }) => `${$fontSize}px`};
    font-weight: ${({ $fontWeight }) => $fontWeight || 500};
    line-height: ${({ $lineHeight }) => `${$lineHeight}px`};
    cursor: pointer;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    border-radius: ${({ $borderRadius }) => `${$borderRadius}px`};

    ${({ $isOnlyIcon }) => {
        if ($isOnlyIcon) {
            return css`
                background: ${colors.white};
                border: none;
            `
        }

        return css<ButtonProps>`
            background: ${({ $btnType, $isOutline }) =>
                $isOutline ? colors.white : backgroundColors[$btnType]};
            color: ${({ $btnType, $isOutline }) =>
                $isOutline
                    ? textColorsOutline[$btnType]
                    : textColors[$btnType]};
            border: 1px solid
                ${({ $btnType, $isOutline }) =>
                    $isOutline
                        ? borderColorsOutline[$btnType]
                        : borderColors[$btnType]};
        `
    }}

    &:disabled {
        cursor: default;
        border: 1px solid ${colors.green.primaryDisabled};
        background: ${colors.green.primaryDisabled};
        color: ${colors.white};
    }
`

// height: ${({ height }) => `${height}px`};
//     width: ${({ $width }) => `${$width}px` || 'max-content'};
//     ${styles.flexCenter};
//     gap: 10px;
//     padding: 0 15px;
//     background: ${({ $btnType, $isOutLine }) =>
//         $isOutLine ? colors.white : backgroundColors[$btnType]};
//     color: ${({ $btnType, $isOutLine }) =>
//         $isOutLine ? textColorsOutline[$btnType] : textColors[$btnType]};
//     border: 1px solid
//         ${({ $btnType, $isOutLine }) =>
//             $isOutLine
//                 ? borderColorsOutline[$btnType]
//                 : borderColors[$btnType]};
//     border-radius: ${({ $borderRadius }) => `${$borderRadius}px`};
//     font-size: ${({ $fontSize }) => `${$fontSize}px`};
//     font-weight: ${({ $fontWeight }) => $fontWeight || 500};
//     line-height: ${({ $lineHeight }) => `${$lineHeight}px`};
//     cursor: pointer;
//     box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

// ${({ $btnType, $isOutLine, $borderRadius }) => {
//         const isLinearOutline = $btnType == 'linear' && $isOutLine
//         if (isLinearOutline) {
//             return css`
//                 border: none;
//                 position: relative;
//                 z-index: 1;
//                 background: linear-gradient(
//                     to right,
//                     ${colors.green.fadedPoster} 0%,
//                     ${colors.blue.blessedBlue} 100%
//                 );
//                 &:after {
//                     content: attr(data-text);
//                     background: linear-gradient(
//                         to right,
//                         ${colors.green.fadedPoster} 0%,
//                         ${colors.blue.blessedBlue} 100%
//                     );
//                     background-clip: text;
//                     -webkit-background-clip: text;
//                     -webkit-text-fill-color: transparent;
//                 }
//                 &::before {
//                     content: '';
//                     position: absolute;
//                     left: 1px;
//                     right: 1px;
//                     top: 1px;
//                     bottom: 1px;
//                     z-index: -2;
//                     border-radius: ${$borderRadius + 'px'};
//                     background: ${colors.blue.alice};
//                 }
//             `
//         }
//     }}
