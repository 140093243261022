import styled from 'styled-components'
import { colors } from '../../../_principles'

export const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 18px;
    border-top: 1px solid ${colors.green.primaryDisabled};
`
