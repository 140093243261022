import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import FormView from './components/Form'
import {
    Form,
    initialValues,
    ResSchema,
    validationSchema,
} from './utils/constants'
import { TitleWrapper } from './styles'
import { FormWrapper } from '../components/FormWrapper'
import Icon from '../../../../components/Icon'
import SpacerY from '../../../../components/Spacer/Y'
import Title from '../../../../components/Title'
import useMutation from '../../../../hooks/useMutation'
import { assets, storage } from '../../../../utils'
import { api, url } from '../../../../utils/constants'

type Props = {
    tempID: string
}

export const SetUserID = ({ tempID }: Props) => {
    const navigate = useNavigate()
    const { loading, postAction } = useMutation<Form, ResSchema>({
        apiURL: `${api.auth}/create`,
    })

    const handleSubmit = async (value: Form) => {
        const params = new FormData()
        params.append('temp_id', tempID)
        params.append('user_id', value.user_id)

        const res = await postAction(params)
        if (res.error) return
        if (!res.data) return
        storage.setUser(res.data.resources.user)
        toast.success(res.data.message)
        navigate(`/${res.data.resources.user.user_id}` ?? url.home)
    }

    return (
        <FormWrapper>
            <SpacerY height={32} />
            <Icon
                src={assets.userID}
                width={40}
                height={40}
                alt="mokusy user ID"
            />
            <SpacerY height={16} />
            <TitleWrapper>
                <Title fontSize={24} lineHeight={1.4}>
                    ユーザーIDを
                    <br />
                    設定してください
                </Title>
            </TitleWrapper>
            <SpacerY height={24} />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => <FormView loading={loading} {...props} />}
            </Formik>
        </FormWrapper>
    )
}
