import styled from 'styled-components'

type AvatarProps = {
    width: number
    height: number
    $canEdit?: boolean
}

export const Wrapper = styled.img<AvatarProps>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;

    ${({ $canEdit }) =>
        $canEdit &&
        `
        cursor: pointer;
        filter: brightness(50%);
    `}
`
