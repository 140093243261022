import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { colors } from '../../_principles'

type CardProps = {
    width?: number
    height?: number
}

type CardBodyProps = {
    to?: string
}

type ImageProps = {
    $count?: number
}

export const Wrapper = styled.div`
    position: relative;
    padding: 16px 24px;
    border-bottom: 1px solid ${colors.green.primaryDisabled};
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
`

export const CardBody = styled(NavLink)<CardBodyProps>`
    ${({ to }) =>
        to ? `cursor: pointer;` : 'cursor: default; pointer-events: none;'};
    text-decoration: none;
`

export const TagWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
`

export const TextWrapper = styled.div``

export const ImageWrapper = styled.div<ImageProps>`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 4px;

    ${({ $count }) =>
        $count === 1 &&
        `
        flex-direction: row;
        justify-content: center;
    `}

    ${({ $count }) =>
        $count === 2 &&
        `
        flex-direction: row;
        justify-content: space-between;
    `}

    ${({ $count }) =>
        $count === 3 &&
        `
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 4px;

        & > :nth-child(1) {
            grid-row: span 2;
        }
    `}

    ${({ $count }) =>
        $count === 4 &&
        `
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 4px;
    `}
`

export const Image = styled.img<ImageProps>`
    width: 100%;
    max-height: 220px;
    border-radius: 8px;

    ${({ $count }) =>
        $count === 1 &&
        `
        width: 100%;
    `}

    ${({ $count }) =>
        $count === 2 &&
        `
        width: calc(50% - 2px);
    `}

    ${({ $count }) =>
        $count === 3 &&
        `
        &:nth-child(1) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:nth-child(2),
        &:nth-child(3) {
            width: 100%;
            height: 110px;
            object-fit: cover;
        }
    `}

    ${({ $count }) =>
        $count === 4 &&
        `
        width: 100%;
        height: 110px;
        object-fit: cover;
    `}
`

export const Footer = styled.div<{ $height: number }>`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: space-between;

    & > * {
        height: ${({ $height }) => $height}px;
        display: inline-flex;
        align-items: center;
    }
`

export const CommentCountWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

export const ActionCardWrapper = styled.div`
    position: absolute;
    top: 50px;
    right: 24px;
`

export const ActionModalOverlay = styled.div`
    background-color: transparent;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
`
