import styled from 'styled-components'
import { colors } from '../../../_principles'

export const Wrapper = styled.div`
    position: relative;
`

export const CommentFormWrapper = styled.div`
    padding: 8px 24px;
    display: flex;
    align-items: center;
    gap: 8px;
`

export const AvatarWrapper = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`

export const FieldWrapper = styled.div`
    min-width: 223px;

    & textarea {
        overflow: hidden;
        resize: none;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        line-height: 24px;
        outline: none;
        color: ${colors.characterTitle85};
    }
`
