import { useEffect, useMemo, useState } from 'react'
import { FieldArray, Form, FormikProps } from 'formik'
import { toast } from 'react-toastify'
import {
    CountdownWrapper,
    FieldWrapper,
    SelectWrapper,
    TagWrapper,
    TextWrapper,
    ThumbWrapper,
    ViewWrapper,
} from './styles'
import SelectFlavorView from '../SelectFlavorView'
import {
    fields,
    FlavorReqSchema,
    FlavorResSchema,
    PostForm,
} from '../../utils/constants'
import { colors } from '../../../../_principles'
import Button from '../../../../components/Button'
import InputField from '../../../../components/InputField'
import SelectFile from '../../../../components/SelectFile'
import SpacerX from '../../../../components/Spacer/X'
import SpacerY from '../../../../components/Spacer/Y'
import Tag from '../../../../components/Tag'
import Text from '../../../../components/Text'
import Thumb from '../../../../components/Thumb'
import useMutation from '../../../../hooks/useMutation'
import {
    acceptImageType,
    api,
    imagePathRegex,
    maxImageSize,
} from '../../../../utils/constants'
import { Flavor } from '../../../../utils/types'
import { compressImage } from '../../../../utils/functions'

type Props = {
    imageCount?: number
    setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
} & FormikProps<PostForm>

function FormView({
    values,
    errors,
    touched,
    setFieldValue,
    imageCount,
    setIsDisabled,
}: Props) {
    const { title, content, images, flavor_ids } = values
    const [flavors, setFlavors] = useState<Flavor[]>([])
    const [selectedFlavors, setSelectedFlavors] = useState<Flavor[]>([])
    const [isSelectFlavorViewOpen, setIsSelectFlavorViewOpen] =
        useState<boolean>(false)
    const [titleCountDown, setTitleCountDown] = useState<number>(15)
    const [contentCountDown, setContentCountDown] = useState<number>(500)
    const { postAction } = useMutation<FlavorReqSchema, FlavorResSchema>({
        apiURL: `${api.flavors}/search`,
    })

    // フレーバーを取得
    useMemo(() => {
        const fetchData = async () => {
            const res = await postAction({ page: -1 })
            if (res.error) return
            if (!res.data) return
            const flavors = res.data.resources.flavors
            setFlavors(flavors)
            const selectedFlavors = flavors.filter((flavor) =>
                flavor_ids?.includes(flavor.id)
            )
            setSelectedFlavors(selectedFlavors)
        }

        fetchData()
    }, [])

    // フォームの変更を監視
    useEffect(() => {
        const titleLength = title.length
        const contentLength = content.length

        setTitleCountDown(15 - titleLength)
        setContentCountDown(500 - contentLength)
    }, [title, content])

    // フレーバーの選択状態を監視
    useEffect(() => {
        const selectedFlavors = flavors.filter((flavor) =>
            flavor_ids?.includes(flavor.id)
        )
        setSelectedFlavors(selectedFlavors)
    }, [flavor_ids])

    useEffect(() => {
        // エラーがある場合はボタンを無効化
        const hasError = Object.keys(errors).length > 0
        setIsDisabled(hasError)
    }, [errors])

    // ファイルをセット
    const handleSetFile = async (file: File) => {
        if (!images) return
        if (!file) return

        const compressedImage = await compressImage(file)
        if (!compressedImage) return

        const isValid = imagePathRegex.test(compressedImage.name)
        if (!isValid) {
            toast.error('画像名に使用できない文字が含まれています')
            return
        }
        // 画像ファイルのサイズが1MB以下かチェック
        if (compressedImage.size > maxImageSize) {
            toast.error('画像のサイズが1MBを超えています')
            return
        }

        setFieldValue('images', [...images, compressedImage])
    }

    return (
        <Form>
            {/* タイトル */}
            <FieldWrapper>
                <SpacerY height={16} />
                <InputField
                    type={fields[0].type}
                    name={fields[0].name}
                    label={fields[0].label}
                    labelColor={colors.green.primary}
                    placeholder={fields[0].placeholder}
                    fontSize={fields[0].fontSize}
                    fontWeight={700}
                    autoFocus={fields[0].autoFocus}
                    paddingLeft={-12}
                    inputColor={colors.green.primary}
                    disableBorder
                />
                <SpacerY height={24} />
                <CountdownWrapper>
                    <Text
                        color={
                            titleCountDown >= 0
                                ? colors.gray.neutral7
                                : colors.red.error
                        }
                        lineHeight={2}
                    >
                        {titleCountDown}
                    </Text>
                </CountdownWrapper>
            </FieldWrapper>

            {/* 本文 */}
            <FieldWrapper>
                <SpacerY height={16} />
                <InputField
                    type={fields[1].type}
                    name={fields[1].name}
                    label={fields[1].label}
                    labelColor={colors.green.primary}
                    placeholder={fields[1].placeholder}
                    fontSize={fields[1].fontSize}
                    autoFocus={fields[1].autoFocus}
                    paddingLeft={-12}
                    disableBorder
                    // errorMessage={errors[fields[0].name]}
                />
                <SpacerY height={24} />
                <CountdownWrapper>
                    <Text
                        color={
                            contentCountDown >= 0
                                ? colors.gray.neutral7
                                : colors.red.error
                        }
                        lineHeight={2}
                    >
                        {contentCountDown}
                    </Text>
                </CountdownWrapper>
            </FieldWrapper>

            {/* フレーバー選択 */}
            <FieldWrapper>
                <SpacerY height={12} />
                <Text
                    color={colors.green.primary}
                    fontSize={14}
                    fontWeight={700}
                    lineHeight={1.5}
                >
                    {fields[2].label}
                </Text>
                <SpacerY height={0.5} />
                <SelectWrapper>
                    {selectedFlavors?.length !== 0 ? (
                        <TagWrapper>
                            {selectedFlavors?.map(
                                (flavor: Flavor, index: number) => (
                                    <Tag key={index}>{flavor.name}</Tag>
                                )
                            )}
                        </TagWrapper>
                    ) : (
                        <TextWrapper>
                            <Text fontSize={14}>
                                フレーバーが選択されていません
                            </Text>
                        </TextWrapper>
                    )}
                    <Button
                        type="button"
                        width={116}
                        height={20}
                        paddingX={8}
                        fontSize={12}
                        isOutline
                        onClick={() => setIsSelectFlavorViewOpen(true)}
                    >
                        フレーバーを選ぶ
                    </Button>
                </SelectWrapper>
                <SpacerY height={12} />
            </FieldWrapper>

            {/* 写真選択 */}
            <FieldWrapper>
                <SpacerY height={12} />
                <Text
                    color={colors.green.primary}
                    fontSize={14}
                    fontWeight={700}
                    lineHeight={1.5}
                >
                    {fields[3].label}
                </Text>
                <SpacerY height={0.5} />
                <FieldArray name={fields[3].name}>
                    {({ push, remove }) => (
                        <SelectWrapper>
                            {images?.length === 0 ? (
                                <TextWrapper>
                                    <Text fontSize={14}>
                                        写真が選択されていません
                                    </Text>
                                </TextWrapper>
                            ) : (
                                <ThumbWrapper>
                                    {images?.map((image, index: number) => (
                                        <Thumb
                                            key={index}
                                            image={image}
                                            width={40}
                                            height={40}
                                        />
                                    ))}
                                    <SpacerX width={8} />
                                    <Text>{images?.length}枚の写真</Text>
                                </ThumbWrapper>
                            )}
                            <SelectFile
                                width={80}
                                height={20}
                                paddingX={8}
                                fontSize={12}
                                disabled={
                                    images && images.length + imageCount! >= 4
                                }
                                isOutline
                                label="写真を選ぶ"
                                accept={acceptImageType}
                                setFile={handleSetFile}
                            />
                        </SelectWrapper>
                    )}
                </FieldArray>
                <SpacerY height={12} />
            </FieldWrapper>

            {/* フレーバー選択画面 */}
            {isSelectFlavorViewOpen && (
                <ViewWrapper>
                    <FieldArray name={fields[2].name}>
                        {({ push, remove }) => (
                            <SelectFlavorView
                                flavorIds={flavor_ids}
                                flavorOptions={flavors}
                                closePage={() =>
                                    setIsSelectFlavorViewOpen(false)
                                }
                                push={push}
                                remove={remove}
                            />
                        )}
                    </FieldArray>
                </ViewWrapper>
            )}
        </Form>
    )
}

export default FormView
