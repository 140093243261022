import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { flexCenter } from '../../_principles/styles'

type LabelProps = {
    color: string
}

export const NavBarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & > a {
        flex: 1;
    }
`

export const MenuItemWrapper = styled(NavLink)`
    ${flexCenter};
    flex-direction: column;
    text-decoration: none;
`

export const ImageWrapper = styled.div`
    width: 24px;
    height: 24px;
    ${flexCenter};
`

export const Image = styled.img``

export const Label = styled.span<LabelProps>`
    color: ${({ color }) => color};
    font-weight: 500;
    font-size: 12px;
`
