import React, { memo } from 'react'
import { Wrapper } from './styles'

type Props = {
    width?: number
    height?: number
    src: string
} & React.ComponentPropsWithoutRef<'img'>

const Logo = memo(function Logo(props: Props) {
    const { width = 260, height = 94, src, ...otherProps } = props

    return (
        <Wrapper
            {...otherProps}
            width={width}
            height={height}
            src={src}
        ></Wrapper>
    )
})

export default Logo
