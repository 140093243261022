import { memo, useEffect, useState } from 'react'
import {
    ActionCardWrapper,
    ActionModalOverlay,
    CardBody,
    CommentCountWrapper,
    Footer,
    Header,
    Image,
    ImageWrapper,
    TagWrapper,
    TextWrapper,
    Wrapper,
} from './styles'
import ActionCard from '../ActionCard'
import Button from '../Button'
import Icon from '../Icon'
import UserInfo from '../UserInfo'
import SpacerY from '../Spacer/Y'
import Tag from '../Tag'
import Text from '../Text'
import Title from '../Title'
import { colors } from '../../_principles'
import { assets, functions, storage } from '../../utils'
import { Flavor, PostImage, User } from '../../utils/types'

type Props = {
    link?: string
    id: number
    title: string
    content: string
    flavors?: Flavor[]
    images?: PostImage[]
    postedAt: string | Date
    user: User
    commentCount?: number
    onEdit?: () => void
    onDelete?: () => void
}

const Card = memo(function Card(props: Props) {
    const {
        link,
        id,
        title,
        content,
        flavors,
        images,
        postedAt,
        user,
        commentCount,
        onEdit,
        onDelete,
    } = props
    const [isOpenActionModal, setIsOpenActionModal] = useState<boolean>(false)
    const authUser = storage.getUser()

    // アクションモーダルの開閉
    const handleActionModal = () => {
        setIsOpenActionModal((prev) => !prev)
    }

    // アクションモーダル以外の部分をクリックするとモーダルを閉じる
    const closeModal = () => {
        setIsOpenActionModal(false)
    }

    // 編集画面へ遷移
    const handleEdit = () => {
        if (user.id !== authUser?.id) return
        setIsOpenActionModal(false)
        onEdit && onEdit()
    }

    // 削除処理
    const handleDelete = async () => {
        setIsOpenActionModal(false)
        onDelete && onDelete()
    }

    return (
        <Wrapper>
            <Header>
                <UserInfo user={user} isActiveLink={user.id !== authUser?.id} />
                {user.id === authUser?.id && (
                    <Button
                        onClick={handleActionModal}
                        icon={
                            <Icon
                                src={assets.threeLeader}
                                width={16}
                                height={16}
                            />
                        }
                    />
                )}
            </Header>
            <SpacerY height={8} />
            <CardBody to={link}>
                <Title
                    fontSize={14}
                    lineHeight={1.5}
                    color={colors.green.primary}
                >
                    {title}
                </Title>
                <SpacerY height={4} />
                <TagWrapper>
                    {flavors?.map((flavor, index) => (
                        <Tag key={index}>{flavor.name}</Tag>
                    ))}
                </TagWrapper>
                {flavors && <SpacerY height={12} />}
                <TextWrapper>
                    <Text color={colors.black}>{content}</Text>
                </TextWrapper>
                <SpacerY height={12} />
                <ImageWrapper $count={images?.length}>
                    {images?.map((image, index) => (
                        <Image
                            key={index}
                            src={image.path}
                            alt={title}
                            $count={images?.length}
                        />
                    ))}
                </ImageWrapper>
                <SpacerY height={12} />
                <Footer $height={17}>
                    <Text>{functions.formatDateTime(postedAt)}</Text>
                    {commentCount !== undefined && (
                        <CommentCountWrapper>
                            <Icon
                                src={assets.commentBubble}
                                alt="Mokusy Comment Bubble Icon"
                                width={14}
                                height={14}
                            />
                            <Text>{commentCount}</Text>
                        </CommentCountWrapper>
                    )}
                </Footer>
            </CardBody>
            {isOpenActionModal && (
                <ActionCardWrapper>
                    <ActionModalOverlay
                        onClick={closeModal}
                    ></ActionModalOverlay>
                    <ActionCard
                        editLabel="投稿を編集"
                        deleteLabel="投稿を削除"
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                </ActionCardWrapper>
            )}
        </Wrapper>
    )
})

export default Card
