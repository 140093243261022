import styled from 'styled-components'
import { colors } from '../../../../_principles'

export const FieldWrapper = styled.div`
    position: relative;
    padding: 0 24px;
    border-bottom: 1px solid ${colors.green.primaryDisabled};
`

export const CountdownWrapper = styled.div`
    position: absolute;
    bottom: 0;
    right: 8px;
`

export const SelectWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4px;
`

export const TagWrapper = styled.div`
    display: flex;
    gap: 4px;
`

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const ThumbWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;
`

export const ViewWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
`
