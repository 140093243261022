import { useEffect, useMemo, useState } from 'react'
import { AxiosError } from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import instance from '../utils/axios'
import { handleError } from '../utils/functions'
import { APIResponse } from '../utils/types'

type Props = {
    apiURL: string
    params?: {
        [key: string]: any
    }
}

function useQuery<ResSchema>({ apiURL, params }: Props) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<APIResponse<ResSchema> | null>(null)
    const [error, setError] = useState<AxiosError | null>(null)

    const navigate = useNavigate()
    const location = useLocation()

    const isChanged = useMemo(() => {
        return JSON.stringify(params)
    }, [params])

    const fetchData = async () => {
        setLoading(true)

        try {
            const res = await instance.get(apiURL, { params })
            // ステータスコードが200以外の場合はエラー
            if (res.data.status !== 200) {
                throw new AxiosError(res.data.message, res.data.status)
            }
            setData(res.data)
        } catch (e) {
            // エラーハンドリング
            const error = e as AxiosError

            handleError(error, navigate, location)
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useMemo(() => {
        fetchData()
    }, [isChanged])

    return { loading, data, error }
}

export default useQuery
