import { useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import FormView from '../components/Form'
import FormLayout from '../components/Layout'
import {
    CreateReqSchema,
    CreateResSchema,
    PostForm,
    validationSchema,
} from '../utils/constants'
import useMutation from '../../../hooks/useMutation'
import { storage } from '../../../utils'
import { api, url } from '../../../utils/constants'

export default function PostCreate() {
    const user = storage.getUser()
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const navigate = useNavigate()
    const formikRef = useRef<FormikProps<PostForm> | null>(null)
    const { postAction } = useMutation<CreateReqSchema, CreateResSchema>({
        apiURL: `${api.post}`,
    })

    const initialValues: PostForm = {
        ['title']: '',
        ['content']: '',
        ['images']: [],
        ['flavor_ids']: [],
    }

    const handleSubmit = async (values: PostForm) => {
        if (!user) return

        const params = new FormData()
        params.append('title', values.title)
        params.append('content', values.content)
        values.images?.forEach((image, index) =>
            params.append(`images[${index}]`, image, image.name)
        )
        values.flavor_ids?.forEach((flavor_id, index) =>
            params.append(`flavor_ids[${index}]`, flavor_id.toString())
        )

        const res = await postAction(params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        if (res.error) return
        if (!res.data) return
        toast.success(res.data.message)
        navigate(`/${user.user_id}`)
    }

    return (
        <FormLayout isDisabled={isDisabled} formikRef={formikRef}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                innerRef={formikRef}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <FormView setIsDisabled={setIsDisabled} {...props} />
                )}
            </Formik>
        </FormLayout>
    )
}
