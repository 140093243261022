export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const userIDRegex = /^[a-zA-Z0-9_]{5,15}$/
export const imagePathRegex = /^[a-zA-Z0-9_-]+\.(jpeg|jpg|png)$/
export const acceptImageType = 'image/jpeg, image/png, image/jpg'
export const acceptImageExt = '.jpeg, .png, .jpg'
export const maxImageSize = 1 * 1024 * 1024 // 最大1MB
export const formatYYYYMMDD = 'YYYY/MM/DD'
export const formatYYYYMMDDHHmm = 'YYYY/MM/DD HH:mm'

export const baseAPIURL = process.env.REACT_APP_BASE_API_URL

// prefix for url
const prefix = {
    _api: 'api',

    _home: '/',
    _top: 'top',
    _auth: 'auth',
    _loginCallback: 'login-callback',
    _checkExists: 'check-exists',
    _dummyLogin: 'dummy-login',
    _register: 'register',
    _imagePreview: 'image-preview',
    _login: 'login',
    _logout: 'logout',
    _flavor: 'flavor',
    _setting: 'setting',
    _post: 'post',
    _comment: 'comment',
    _privacy: 'privacy',
    _terms: 'terms',

    _new: 'new',
    _edit: 'edit',
    _search: 'search',
    _create: 'create',
    _update: 'update',
    _delete: 'delete',
}

// url
export const url = {
    home: prefix._home,
    top: `/${prefix._top}`,
    loginCallback: `/${prefix._loginCallback}`,
    register: `/${prefix._register}`,
    imagePreview: `/${prefix._imagePreview}`,
    logout: `/${prefix._logout}`,
    mypage: '/:user_id',
    userEdit: `/:user_id/${prefix._edit}`,
    post: `/${prefix._post}`,
    // posts: `/:user_id/${prefix._post}`,
    // postCreate: `/${prefix._post}/${prefix._new}`,
    // postDetail: `/:user_id/${prefix._post}/:post_id`,
    // postEdit: `/:user_id/${prefix._post}/:post_id`,
    comment: `/${prefix._comment}`,
    // commentEdit: `/:user_id/${prefix._post}/:post_id/${prefix._comment}/:comment_id/${prefix._edit}`,
    setting: `/${prefix._setting}`,
    privacy: `/${prefix._privacy}`,
    terms: `/${prefix._terms}`,
}

export const api = {
    checkUserID: `/${prefix._api}/:user_id/${prefix._checkExists}`,
    dummyLogin: `/${prefix._api}/${prefix._auth}/${prefix._dummyLogin}`,
    flavors: `/${prefix._api}/${prefix._flavor}`,
    login: `/${prefix._api}/${prefix._auth}/${prefix._login}`,
    loginCallback: `/${prefix._api}/${prefix._auth}/${prefix._loginCallback}`,
    logout: `/${prefix._api}/${prefix._auth}/${prefix._logout}`,
    auth: `/${prefix._api}/${prefix._auth}`,
    post: `/${prefix._api}/${prefix._post}`,
    comment: `/${prefix._api}/${prefix._comment}`,
    setting: `/${prefix._api}/${prefix._setting}`,
    // postCreate: `/${prefix._api}/${prefix._post}`,
    // postDetail: `/${prefix._api}/:user_id/${prefix._post}/:post_id`,
    // postUpdate: `/${prefix._api}/${prefix._post}/${prefix._update}`,
    // postDelete: `/${prefix._api}/${prefix._post}/${prefix._delete}`,
    // commentCreate: `/${prefix._api}/${prefix._comment}`,
    // commentUpdate: `/${prefix._api}/${prefix._comment}/${prefix._update}`,
    // commentDelete: `/${prefix._api}/${prefix._comment}/${prefix._delete}`,
    // userCreate: `/${prefix._api}/${prefix._auth}/${prefix._create}`,
}
