import styled from 'styled-components'
import { colors } from '../../_principles'
import { InputType } from '../../utils/types'

export const InputWrapper = styled.div<{
    $width?: number
    $height: number
    $error: boolean
    $fontSize: number
    $fontWeight: number
    $lineHeight: number
    $color: string
    $backgroundColor: string
    $type: InputType
    $paddingRight: number
    $borderRadius: number
    $placeholderTop: number
    $textAlign: string
    $paddingLeft: number
    $disableBorder: boolean
}>`
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    height: ${({ $height }) => `${$height}px`};
    position: relative;

    input {
        width: 100%;
        height: 100%;
        padding: 0
            ${({ $type, $paddingRight }) =>
                $type == 'password' ? '40px' : `${$paddingRight}px`}
            0
            ${({ $paddingLeft }) =>
                !$paddingLeft ? '12px' : `${$paddingLeft}px`};
        border: none;
        border-bottom: ${({ $error, $disableBorder }) =>
            $disableBorder
                ? 'none'
                : `1px solid ${$error ? colors.red.dustRed5 : colors.green.primary}`};
        border-radius: ${({ $borderRadius }) => `${$borderRadius}px`};
        box-sizing: border-box;
        font-size: ${({ $fontSize }) => `${$fontSize}px`};
        font-weight: ${({ $fontWeight }) => $fontWeight};
        line-height: ${({ $lineHeight }) => `${$lineHeight}px`};
        outline: none;
        color: ${({ $color }) => $color};
        background-color: ${({ $backgroundColor }) => $backgroundColor};
        text-align: ${({ $textAlign }) => $textAlign};

        &::placeholder {
            font-size: ${({ $fontSize }) => `${$fontSize}px`};
            font-weight: normal;
            line-height: ${({ $lineHeight }) => `${$lineHeight}px`};
            color: ${colors.gray.neutral1};
            position: relative;
            top: ${({ $placeholderTop }) => `${$placeholderTop}px`};
            white-space: break-spaces;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const EyeImage = styled.img`
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
`
