import { memo } from 'react'
import ReactDOM from 'react-dom'
import { ModalBody, ModalOverlay, ModalWrapper } from './styles'

type Props = {
    isOpen: boolean
    closeModal: () => void
    children: React.ReactNode
    isOverflowVisible?: boolean
}

const Modal = memo(function Modal({
    isOpen,
    closeModal,
    children,
    isOverflowVisible,
}: Props) {
    return isOpen
        ? ReactDOM.createPortal(
              <ModalWrapper $isOpen={isOpen}>
                  <ModalOverlay onClick={closeModal}></ModalOverlay>
                  <ModalBody $isOverflowVisible={isOverflowVisible}>
                      {children}
                  </ModalBody>
              </ModalWrapper>,
              document.body
          )
        : null
})

export default Modal
