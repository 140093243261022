import styled from 'styled-components'

type SubTitleProps = {
    $fontSize: number
    $fontWeight: number
    $lineHeight: number
    $letterSpacing: number
    $color: string
}

export const Wrapper = styled.span<SubTitleProps>`
    color: ${({ $color }) => $color};
    font-size: ${({ $fontSize }) => $fontSize}px;
    font-weight: ${({ $fontWeight }) => $fontWeight};
    line-height: ${({ $lineHeight }) => $lineHeight};
    letter-spacing: ${({ $letterSpacing }) => $letterSpacing}px;
`
