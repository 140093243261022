import { SetStateAction, memo, useEffect, useRef, useState } from 'react'
import {
    CardOptions,
    CardOptionsItem,
    HeaderCardOptions,
    Icon,
    Nodata,
    OptionItem,
    OptionWrapper,
    SelectButton,
    Wrapper,
} from './styles'
// import { NameLabels, Options } from '../../utils/types'
import { Options } from '../../utils/types'
// import { assets } from '../../utils'
import { colors } from '../../_principles'

type Props = {
    name: string
    placeholder?: string
    options: Options[]
    height?: number
    backgroundColor?: string
    error?: boolean
    setFieldTouched?: (field: string, isTouched: boolean) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFieldValue?: (field: string, value: any) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any
    borderRadius?: number
    hasOptionName?: boolean
    optionsName?: Options[]
    // nameLabels?: NameLabels
    tabIndex?: number
    openPullDown?: boolean
    namePullDown?: string | null
    setOpenPullDown?: React.Dispatch<SetStateAction<boolean>>
    setNamePullDown?: React.Dispatch<SetStateAction<string | null>>
    widthSelect?: number
}

const Select = memo(function Select({
    name,
    placeholder,
    options,
    height = 32,
    backgroundColor = colors.white,
    error = false,
    setFieldTouched,
    setFieldValue,
    value,
    borderRadius = 2,
    hasOptionName,
    optionsName,
    // nameLabels,
    tabIndex,
    openPullDown,
    namePullDown,
    setOpenPullDown,
    setNamePullDown,
    widthSelect,
}: Props) {
    const [dirty, setDirty] = useState(value ?? false)
    const [open, setOpen] = useState(false)
    const selectRef = useRef<HTMLDivElement | null>(null)
    const buttonRef = useRef<HTMLButtonElement | null>(null)

    const openOption = () => {
        setOpen(true)
        setOpenPullDown && setOpenPullDown(false)
        setNamePullDown && setNamePullDown(null)
    }

    const closeOption = () => {
        setOpen(false)
    }

    const handleSelect = (value: string) => {
        setFieldValue && setFieldValue(name, value)
        setDirty(true)
        closeOption()
    }

    const selectedValue = options.find((option) => option.value == value)?.label

    const renderOptions = (
        label: string,
        hasBorderRight: boolean,
        options?: Options[]
    ) => (
        <CardOptionsItem>
            {hasOptionName && <HeaderCardOptions>{label}</HeaderCardOptions>}
            {options?.map((option) => (
                <OptionItem
                    key={option.value}
                    type="button"
                    onClick={() => handleSelect(option.value)}
                    $isSelected={option.value == value}
                    $hasBorderRight={hasBorderRight}
                >
                    {option.label}
                </OptionItem>
            ))}
        </CardOptionsItem>
    )

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Tab' && namePullDown === name) {
                closeOption()
                setFieldTouched && setFieldTouched(name, true)
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, namePullDown])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                selectRef.current &&
                !selectRef.current.contains(event.target as Node)
            ) {
                closeOption()
                setFieldTouched && setFieldTouched(name, true)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (openPullDown && namePullDown && namePullDown === name) openOption()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, namePullDown, openPullDown])

    return (
        <Wrapper $height={height}>
            <SelectButton
                $height={height}
                $backgroundColor={backgroundColor}
                $error={error}
                $dirty={dirty}
                $borderRadius={borderRadius}
                type="button"
                onClick={openOption}
                ref={buttonRef}
                tabIndex={tabIndex}
                name={name}
            >
                {dirty ? selectedValue : placeholder}
            </SelectButton>
            {open && (
                <>
                    <OptionWrapper
                        $height={height}
                        $width={widthSelect || buttonRef?.current?.offsetWidth}
                        ref={selectRef}
                    >
                        {options?.length !== 0 && (
                            <CardOptions $hasOptionName={hasOptionName}>
                                {renderOptions(
                                    // nameLabels?.code || '',
                                    '',
                                    hasOptionName || false,
                                    options
                                )}
                                {hasOptionName &&
                                    renderOptions(
                                        // nameLabels?.name || '',
                                        '',
                                        false,
                                        optionsName
                                    )}
                            </CardOptions>
                        )}
                        {options?.length === 0 && <Nodata>データなし</Nodata>}
                    </OptionWrapper>
                </>
            )}
            {/* <Icon
                src={assets.inputSuffix}
                alt="input-suffix"
                width={14}
                onClick={openOption}
            /> */}
        </Wrapper>
    )
})

export default Select
