import styled from 'styled-components'
import { colors } from '../../_principles'

type CoverImageProps = {
    width?: number
    height: number
    $canEdit?: boolean
}

export const Wrapper = styled.img<CoverImageProps>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => height}px;
    object-fit: cover;
    object-position: center;
    border-radius: 0;

    ${({ $canEdit }) =>
        $canEdit &&
        `
        cursor: pointer;
        filter: brightness(50%);
    `}
`
