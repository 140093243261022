import React, { memo } from 'react'
import { Wrapper } from './styles'
import { colors } from '../../_principles'

type Props = {
    fontSize?: number
    fontWeight?: number
    lineHeight?: number
    letterSpacing?: number
    color?: string
} & React.ComponentPropsWithoutRef<'span'>

const Title = memo(function Title(props: Props) {
    const {
        fontSize = 16,
        fontWeight = 700,
        lineHeight = 0,
        letterSpacing = 0,
        color = colors.black,
        ...otherProps
    } = props

    return (
        <Wrapper
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $lineHeight={lineHeight}
            $letterSpacing={letterSpacing}
            $color={color}
            {...otherProps}
        ></Wrapper>
    )
})

export default Title
