import { memo } from 'react'
import { ElementWrapper, HeaderWrapper } from './styles'
import Title from '../../components/Title'

type Props = {
    title?: string
    leftEle?: React.ReactNode
    rightEle?: React.ReactNode
    isBorderBottom?: boolean
}

const Header = memo(function Header(props: Props) {
    const { title, leftEle, rightEle, isBorderBottom } = props

    return (
        <HeaderWrapper $isBorderBottom={isBorderBottom}>
            <ElementWrapper $isLeft>{leftEle ?? leftEle}</ElementWrapper>
            <Title fontSize={14}>{title ?? title}</Title>
            <ElementWrapper $isRight>{rightEle ?? rightEle}</ElementWrapper>
        </HeaderWrapper>
    )
})

export default Header
