import styled from 'styled-components'
import { colors } from '../../_principles'

type BottomButtonProps = {
    $isDelete?: boolean
}

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray.neutral1};
    border-radius: 10px;
    overflow: hidden;
`

export const TitleWrapper = styled.div``

export const TextWrapper = styled.div``

export const BottomButtonWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid ${colors.gray.neutral1};

    & > :first-child {
        border-right: 1px solid ${colors.gray.neutral1};
    }

    & > :last-child {
        border-left: 1px solid ${colors.gray.neutral1};
    }
`

export const BottomButton = styled.button<BottomButtonProps>`
    padding: 8px 0;
    font-size: 14px;
    font-weight: 700;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${({ $isDelete }) =>
        $isDelete ? colors.red.error : colors.green.primary};
`
