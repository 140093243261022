import { RouterProvider } from 'react-router-dom'
import { router } from './routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'

export default function App(): React.ReactElement {
    return (
        <>
            <RouterProvider router={router} />
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                closeOnClick={false}
                draggable
            />
        </>
    )
}
