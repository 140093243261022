import styled from 'styled-components'
import { colors } from '../../_principles'

type CoverImageProps = {
    width?: number
    height?: number
}

export const Wrapper = styled.div`
    position: relative;
`

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
`

export const ProfileWrapper = styled.div`
    max-width: 700px;
`

export const CoverWrapper = styled.div`
    width: 100%;
    z-index: 10;
    position: relative;
`

export const AvatarWrapper = styled.div`
    width: 67px;
    height: 67px;
    position: absolute;
    top: 106px;
    left: 24px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 100;
`

export const MenuWrapper = styled.div`
    padding: 12px 18px;
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 8px;
    position: relative;
`

export const TitleWrapper = styled.div`
    padding: 8px 18px;
`
