import { FormikProps } from 'formik'
import { useNavigate } from 'react-router-dom'
import { PostForm } from '../../utils/constants'
import Back from '../../../../components/Back'
import Button from '../../../../components/Button'
import Layout from '../../../../layouts/Layout'

type Props = {
    isEdit?: boolean
    isDisabled: boolean
    formikRef: React.MutableRefObject<FormikProps<PostForm> | null>
    children: React.ReactNode
}

function FormLayout({ isEdit, isDisabled, formikRef, children }: Props) {
    const navigate = useNavigate()

    // 戻るボタン
    const handleBack = () => {
        navigate(-1)
    }

    return (
        <Layout
            headerLeftEle={
                <Back
                    type="button"
                    width={65}
                    height={25}
                    text={'キャンセル'}
                    onClick={handleBack}
                />
            }
            headerRightEle={
                <Button
                    type="submit"
                    width={66}
                    height={25}
                    paddingY={4}
                    paddingX={8}
                    fontSize={10}
                    fontWeight={700}
                    disabled={isDisabled}
                    onClick={() => formikRef.current?.submitForm()}
                >
                    {isEdit ? '保存する' : '投稿する'}
                </Button>
            }
            isVisibleBottomNav={false}
            isHeaderBorderBottom={true}
        >
            {children}
        </Layout>
    )
}

export default FormLayout
