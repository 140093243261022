import styled from 'styled-components'

type IconProps = {
    width?: number
    height?: number
}

export const Wrapper = styled.img<IconProps>`
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: ${({ height }) => (height ? `${height}px` : '40px')};
`
