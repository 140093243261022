const colors = {
    white: '#fff',
    black: '#000',
    blackDisable: '#00000040',
    blackNavigation: '#2A3233',
    characterTitle85: 'rgba(0, 0, 0, 0.85)',
    green: {
        fadedPoster: '#81b78d',
        polarGreen6: '#52C41A',
        primary: '#377376',
        primary2: '#529BA3',
        primaryDisabled: '#E6F7F8',
    },
    blue: {
        blessedBlue: '#439ac8',
        primary: '#1890FF',
        insigniaWhite: '#f3f7fb',
        alice: '#e6f7ff',
        primary4: '#69C0FF',
    },
    gray: {
        neutral1: '#8A9799',
        neutral2: '#FAFAFA',
        neutral3: '#F5F5F5',
        neutral4: '#F2F2F2',
        neutral5: '#F0F0F0',
        neutral6: '#D9D9D9',
        neutral7: '#666666',
    },
    red: {
        error: '#A35252',
        dustRed5: '#FF4D4F',
        dustRed6: '#F5222D',
        dustRed7: '#CF1322',
        dustRed8: '#ED6A5E',
    },
    conditionDivider: 'rgba(0, 0, 0, 0.06)',
    characterDisabledPlaceholder25: 'rgba(0, 0, 0, 0.25)',
}

export default colors
