import { SetStateAction, useState } from 'react'
import { Field } from 'formik'
import { FormikFieldType, InputType } from '../../utils/types'
import { EyeImage, InputWrapper } from './styles'
import { assets } from '../../utils'
import { colors } from '../../_principles'
import { on } from 'events'

type Props = {
    name: string
    maxLength?: number
    placeholder?: string
    width?: number
    height?: number
    error?: boolean
    type?: InputType
    fontSize?: number
    fontWeight?: number
    lineHeight?: number
    color?: string
    backgroundColor?: string
    paddingLeft?: number
    paddingRight?: number
    borderRadius?: number
    readOnly?: boolean
    autoFocus?: boolean
    placeholderTop?: number
    textAlign?: string
    disableInput?: boolean
    disableBorder?: boolean
    onSetNameInput?: React.Dispatch<SetStateAction<string | null>>
} & React.ComponentPropsWithoutRef<'input'>

function Input({
    name,
    maxLength = 15,
    placeholder,
    width,
    height = 40,
    error = false,
    type = 'text',
    fontSize = 16,
    fontWeight = 500,
    lineHeight = 24,
    color = colors.characterTitle85,
    backgroundColor = colors.white,
    paddingLeft = 12,
    paddingRight = 12,
    borderRadius = 2,
    readOnly = false,
    autoFocus = false,
    placeholderTop = 0,
    textAlign = 'left',
    onSetNameInput,
    disableInput,
    disableBorder = false,
    ...props
}: Props) {
    const [inputType, setInputType] = useState<InputType>(type)

    const toggleEye = () => {
        setInputType((prev) => (prev == 'password' ? 'text' : 'password'))
    }

    const EyeIcon = () => {
        return (
            <EyeImage
                src={
                    inputType == 'password'
                        ? assets.eyeInvisible
                        : assets.eyeVisible
                }
                alt="eye"
                width={16}
                onClick={toggleEye}
            />
        )
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (readOnly) {
            if (event.key === 'Tab' || event.key === 'Enter') {
                event.preventDefault()
            }
        }
        if (disableInput) {
            if (event.key === 'Tab') {
                event.preventDefault()
            }
        }
        if (event.key === 'Enter') {
            const inputElement = event.target as HTMLInputElement
            onSetNameInput && onSetNameInput(inputElement.name)
        }
    }

    return (
        <Field name={name}>
            {({ field }: FormikFieldType) => (
                <InputWrapper
                    $width={width}
                    $height={height}
                    $error={error}
                    $fontSize={fontSize}
                    $fontWeight={fontWeight}
                    $lineHeight={lineHeight}
                    $type={type}
                    $color={color}
                    $backgroundColor={backgroundColor}
                    $paddingLeft={paddingLeft}
                    $paddingRight={paddingRight}
                    $borderRadius={borderRadius}
                    $disableBorder={disableBorder}
                    $placeholderTop={placeholderTop}
                    $textAlign={textAlign}
                >
                    <input
                        type={inputType}
                        placeholder={placeholder}
                        {...field}
                        {...props}
                        autoFocus={autoFocus}
                        maxLength={maxLength}
                        readOnly={readOnly}
                        disabled={readOnly || disableInput}
                        onKeyDown={handleKeyDown}
                        onFocus={() => {
                            onSetNameInput && onSetNameInput(null)
                        }}
                    />
                    {type == 'password' ? <EyeIcon /> : null}
                </InputWrapper>
            )}
        </Field>
    )
}

export default Input
