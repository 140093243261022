import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
`

export const AvatarWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    word-break: break-all;
    word-wrap: break-word;
    hyphens: auto;
`
