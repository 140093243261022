import { useEffect, useState } from 'react'
import { FormikProps } from 'formik'
import { TextWrapper } from './styles'
import {
    checkExistsReqSchema,
    checkExistsResSchema,
    Form,
} from '../../utils/constants'
import Button from '../../../../../../components/Button'
import InputField from '../../../../../../components/InputField'
import SpacerY from '../../../../../../components/Spacer/Y'
import Text from '../../../../../../components/Text'
import useMutation from '../../../../../../hooks/useMutation'
import { userIDRegex } from '../../../../../../utils/constants'

type Props = {
    loading: boolean
} & FormikProps<Form>

function FormView({
    errors,
    loading,
    touched,
    values,
    submitForm,
    setFieldError,
}: Props) {
    const { user_id } = values
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const { postAction } = useMutation<
        checkExistsReqSchema,
        checkExistsResSchema
    >({ apiURL: `/api/${user_id}/check-exists` })

    useEffect(() => {
        // ユーザーIDが変更されたら重複チェック
        const checkUserID = async () => {
            const res = await postAction()
            if (res.error) return
            if (!res.data) return
            if (res.data.resources.is_exists) {
                setFieldError('user_id', 'このユーザーIDは既に使われています')
            } else {
                setFieldError('user_id', undefined)
            }
        }

        // ユーザーIDが正規表現にマッチしたら重複チェック
        if (userIDRegex.test(user_id)) {
            checkUserID()
        }
    }, [user_id, setFieldError])

    useEffect(() => {
        // ボタンの状態を更新
        if (!userIDRegex.test(user_id)) {
            setIsDisabled(true)
            return
        }
        setIsDisabled(errors.user_id != undefined)
    }, [user_id, errors.user_id])

    return (
        <>
            <InputField
                type="text"
                name="user_id"
                placeholder="ユーザーID(5~15文字)"
                fontSize={13}
                autoFocus
                width={311}
                error={errors.user_id != undefined && touched.user_id}
                errorMessage={errors.user_id}
            />
            <SpacerY height={8} />
            <TextWrapper>
                <Text fontSize={13} lineHeight={1.5}>
                    ユーザ名は英数字と'_'(アンダーバー)が使えます
                    <br />
                    投稿やマイページなどに表示されます。
                    <br />
                    後から変更できます。
                </Text>
            </TextWrapper>
            <SpacerY height={14} />
            <Button
                type="submit"
                height={40}
                borderRadius={62}
                width={311}
                fontWeight={700}
                disabled={isDisabled || loading}
                onClick={submitForm}
            >
                ユーザーIDを設定
            </Button>
        </>
    )
}

export default FormView
