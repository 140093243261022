import { memo } from 'react'
import { Wrapper } from './styles'
import { colors } from '../../_principles'

type Props = {
    height?: number
    fontSize?: number
    fontWeight?: number
    lineHeight?: number
    letterSpacing?: number
    color?: string
    backgroundColor?: string
    borderColor?: string
    borderRadius?: number
} & React.ComponentPropsWithoutRef<'span'>

const Tag = memo(function Tag(props: Props) {
    const {
        height = 16,
        fontSize = 13,
        fontWeight = 500,
        lineHeight = 0,
        letterSpacing = 0,
        color = colors.white,
        backgroundColor = colors.green.primary,
        borderColor = colors.green.primary2,
        borderRadius = 400,
        ...otherProps
    } = props

    return (
        <Wrapper
            $height={height}
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $lineHeight={lineHeight}
            $letterSpacing={letterSpacing}
            $color={color}
            $backgroundColor={backgroundColor}
            $borderColor={borderColor}
            $borderRadius={borderRadius}
            {...otherProps}
        ></Wrapper>
    )
})

export default Tag
