import styled from 'styled-components'
import { colors } from '../../_principles'

type ModalWrapperProps = {
    $isOpen: boolean
    $isOverflowVisible?: boolean
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
    width: 100vw;
    height: 100vh;
    display: ${(props) => (props.$isOpen ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
`

export const ModalOverlay = styled.div`
    background-color: ${colors.black};
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.15;
    z-index: 1;
`

export const ModalBody = styled.div<
    Pick<ModalWrapperProps, '$isOverflowVisible'>
>`
    position: relative;
    z-index: 2;
    max-height: 96vh;
    overflow-y: ${({ $isOverflowVisible }) =>
        $isOverflowVisible ? 'visible' : 'auto'};

    width: 70%;
    max-width: 300px;
`
