import * as Yup from 'yup'
import { InputType, User } from '../../../../utils/types'

export const validationSchema = Yup.object().shape({
    ['name']: Yup.string().required('ユーザ名を入力してください'),
    ['avatar']: Yup.mixed<File>(),
    ['cover_image']: Yup.mixed<File>(),
})

export interface UserForm extends Yup.InferType<typeof validationSchema> {}

export type FieldItem = {
    type: InputType
    name: keyof UserForm
    label: string
    placeholder?: string
    fontSize?: number
}

export const fields: FieldItem[] = [
    {
        type: 'text',
        name: 'name',
        label: '名前',
    },
    {
        type: 'file',
        name: 'avatar',
        label: 'アイコン',
    },
    {
        type: 'file',
        name: 'cover_image',
        label: 'カバー画像',
    },
]

export type ReqSchema = UserForm

export type ResSchema = {
    user: User
}
