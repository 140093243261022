import addPhoto from '../assets/images/add_photo.svg'
import addPost from '../assets/images/add_post.png'
import back from '../assets/images/arrow_back.png'
import commentBubble from '../assets/images/comment_bubble.svg'
import eyeVisible from '../assets/images/eye_visible.svg'
import eyeInvisible from '../assets/images/eye_invisible.svg'
import googleIcon from '../assets/images/google_icon.png'
import home from '../assets/images/home.png'
import logo from '../assets/images/mokusy_logo.png'
import mypageActive from '../assets/images/mypage_active.png'
import panZoom from '../assets/images/pan_zoom.svg'
import postList from '../assets/images/post_list.png'
import setting from '../assets/images/setting.svg'
import threeLeader from '../assets/images/three_leader.svg'
import userAvatar from '../assets/images/user_avatar.png'
import userCover from '../assets/images/user_cover.png'
import userID from '../assets/images/user_id.png'

const assets = {
    addPhoto,
    addPost,
    back,
    commentBubble,
    eyeVisible,
    eyeInvisible,
    googleIcon,
    home,
    logo,
    mypageActive,
    panZoom,
    postList,
    setting,
    threeLeader,
    userAvatar,
    userCover,
    userID,
}

export default assets
