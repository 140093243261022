import styled from 'styled-components'

type BackProps = {
    width: number
    height: number
}

export const Wrapper = styled.button<BackProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
`

export const Element = styled.img<BackProps>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
`

export const Text = styled.span`
    font-size: 12px;
`
