import React, { memo } from 'react'
import { Element, Text, Wrapper } from './styles'
import { assets } from '../../utils'

type Props = {
    width?: number
    height?: number
    withIcon?: boolean
    text?: string
} & React.ComponentPropsWithoutRef<'button'>

const Back = memo(function Back(props: Props) {
    const { width = 24, height = 24, withIcon, text, ...otherProps } = props

    return (
        <Wrapper {...otherProps} width={width} height={height}>
            {withIcon && (
                <Element width={width} height={height} src={assets.back} />
            )}
            {text && <Text>{text}</Text>}
        </Wrapper>
    )
})

export default Back
