import React, { memo, useRef } from 'react'
import { Wrapper } from './styles'
import { ButtonType, InputType } from '../../utils/types'

export type Props = {
    width?: number
    height?: number
    paddingY?: number
    paddingX?: number
    btnType?: ButtonType
    borderRadius?: number
    fontSize?: number
    fontWeight?: number
    lineHeight?: number
    isOutline?: boolean
    icon?: React.ReactElement
    disabled?: boolean
    label?: string
    setFile: (value: File) => void
    accept?: string
} & React.ComponentPropsWithoutRef<'button'>

const SelectFile = memo(function SelectFile(props: Props) {
    const {
        width = 32,
        height = 32,
        paddingY = 0,
        paddingX = 15,
        btnType = 'primary',
        borderRadius = 400,
        fontSize = 14,
        fontWeight = 500,
        lineHeight = 22,
        isOutline = false,
        icon,
        disabled,
        label,
        setFile,
        accept,
        ...otherProps
    } = props
    const inputRef = useRef<HTMLInputElement>(null)

    // ファイル選択時の処理
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0]
        if (file) {
            setFile(file)
        }
        e.target.value = ''
    }

    return (
        <Wrapper
            type="button"
            $width={width}
            height={height}
            $paddingY={paddingY}
            $paddingX={paddingX}
            $btnType={btnType}
            $borderRadius={borderRadius}
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $lineHeight={lineHeight}
            $isOutline={isOutline}
            $isOnlyIcon={!label}
            disabled={disabled}
            onClick={() => inputRef.current?.click()}
            {...otherProps}
        >
            {icon && icon}
            {label}
            <input
                ref={inputRef}
                type={'file' as InputType}
                accept={accept}
                style={{ display: 'none' }}
                onChange={handleChange}
            />
        </Wrapper>
    )
})

export default SelectFile
