import styled from 'styled-components'
import { colors } from '../../_principles'

export const TextareaWrapper = styled.div<{
    $width?: number
    $height: number
    $error: boolean
    $fontSize: number
    $fontWeight: number
    $lineHeight: number
    $backgroundColor: string
    $paddingTop: number
    $paddingBottom: number
    $paddingLeft: number
    $paddingRight: number
    $borderRadius: number
    $placeholderTop: number
    $textAlign: string
    $disableBorder: boolean
}>`
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    height: ${({ $height }) => `${$height}px`};
    position: relative;

    textarea {
        width: 100%;
        height: 100%;
        padding-top: ${({ $paddingTop }) => `${$paddingTop}px`};
        padding-bottom: ${({ $paddingBottom }) => `${$paddingBottom}px`};
        padding-left: ${({ $paddingLeft }) =>
            !$paddingLeft ? '12px' : `${$paddingLeft}px`};
        padding-right: ${({ $paddingRight }) => `${$paddingRight}px`};
        border: none;
        border-bottom: ${({ $error, $disableBorder }) =>
            $disableBorder
                ? 'none'
                : `1px solid ${$error ? colors.red.dustRed5 : colors.green.primary}`};
        border-radius: ${({ $borderRadius }) => `${$borderRadius}px`};
        box-sizing: border-box;
        font-size: ${({ $fontSize }) => `${$fontSize}px`};
        font-weight: ${({ $fontWeight }) => $fontWeight};
        line-height: ${({ $lineHeight }) => `${$lineHeight}px`};
        outline: none;
        color: ${colors.characterTitle85};
        background-color: ${({ $backgroundColor }) => $backgroundColor};
        text-align: ${({ $textAlign }) => $textAlign};

        &::placeholder {
            font-size: ${({ $fontSize }) => `${$fontSize}px`};
            font-weight: normal;
            line-height: ${({ $lineHeight }) => `${$lineHeight}px`};
            color: ${colors.gray.neutral1};
            position: relative;
            top: ${({ $placeholderTop }) => `${$placeholderTop}px`};
            white-space: break-spaces;
        }
    }
`
