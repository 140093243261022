import { styled } from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #fff;
    position: relative;
`

export const LogoWrapper = styled.div``
// export const LogoWrapper = styled.div`
//     position: absolute;
//     top: 243px;
// `
