import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { url } from '../utils/constants'
import { storage } from '../utils'

async function loader({ request }: LoaderFunctionArgs) {
    const user = storage.getUser()
    if (!user) {
        toast.error('ログインしていません')
        return redirect(url.top)
    }
    // TODO: delete this
    const pathname = new URL(request.url).pathname
    if (pathname == url.home) return redirect(`/${user.user_id}`)

    return null
}

function loginLoader() {
    const user = storage.getUser()
    if (user) return redirect(`/${user.user_id}`)
    return null
}

export { loader, loginLoader }
