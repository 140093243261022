import { FormikProps } from 'formik'
import { FieldWrapper, Label } from './styles'
import { UserForm, fields } from '../../utils/constants'
import InputField from '../../../../../components/InputField'
import { User } from '../../../../../utils/types'
import { useEffect } from 'react'

type Props = {
    user: User
    setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
} & FormikProps<UserForm>

function FormView({ errors, touched, values, user, setIsDisabled }: Props) {
    const { name } = values

    // ボタンの状態を更新
    useEffect(() => {
        if (user.name === name) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [name])

    return (
        <FieldWrapper>
            <Label>{fields[0].label}</Label>
            <InputField
                type={fields[0].type}
                name={fields[0].name}
                value={name}
                placeholder=""
                width={240}
                fontSize={16}
                fontWeight={700}
                disableBorder
                error={errors.name != undefined && touched.name}
                errorMessage={errors.name}
            />
        </FieldWrapper>
    )
}

export default FormView
