import styled from 'styled-components'
import { colors } from '../../../../_principles'

export const SearchFieldWrapper = styled.div`
    display: flex;
    padding: 0 18px;
`

export const CheckboxWrapper = styled.div`
    border-bottom: 1px solid ${colors.green.primaryDisabled};
`
