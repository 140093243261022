import styled from 'styled-components'
import { colors } from '../../../../../_principles'

export const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 72px;
`

export const Label = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: ${colors.green.primary};
`
