import { AxiosError } from 'axios'
import imageCompression from 'browser-image-compression'
import dayjs from 'dayjs'
import { Location, NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'
import { url } from './constants'
import { compressImageType } from './types'
import { storage } from '.'

/**
 * Date型のフォーマット化
 *
 * @param   datetime 日時
 * @param   format   フォーマット
 * @returns 日時の文字列
 */
export const formatDateTime = (
    datetime: Date | string,
    format = 'YYYY/MM/DD HH:mm'
) => {
    return dayjs(datetime).isValid() ? dayjs(datetime).format(format) : ''
}

/**
 * リダイレクト
 *
 * @param path     りダイレクト先パス
 * @param navigate ナビゲーション
 * @param location ロケーション
 */
export const handleRedirect = (
    path: string,
    navigate: NavigateFunction,
    location: Location
) => {
    const params = new URLSearchParams()
    params.set('from', location.pathname)
    navigate(`/${path}?${params.toString()}`)
}

/**
 * エラーハンドリング
 *
 * @param   error    エラー
 * @param   navigate ナビゲーション
 * @param   location ロケーション
 *
 * @returns エラーメッセージ
 */
export const handleError = (
    error: AxiosError,
    navigate: NavigateFunction,
    location: Location
) => {
    switch (Number(error.code)) {
        case 400:
            toast.error(error.message ?? 'リクエストエラーが発生しました')
            break
        case 401:
            if (location.pathname === url.top) {
                return
            }
            storage.clearStorage()
            toast.error(error.message ?? '認証エラーが発生しました')

            handleRedirect(url.top, navigate, location)
            break
        case 403:
            toast.error(error.message ?? '権限がありません')
            break
        case 404:
            toast.error(error.message ?? 'ページが見つかりません')
            break
        case 500:
            toast.error(error.message ?? 'システムエラーが発生しました')
            break
        default:
            toast.error(error.message ?? 'システムエラーが発生しました')
    }
}

/**
 * テスト環境かどうかを判別する
 *
 * @returns テスト環境かどうか
 */
export const isTest = () => process.env.NODE_ENV === 'test'

/**
 * 開発環境かどうかを判別する(http://localhost, https://devで始まるURL)
 *
 * @returns 開発環境かどうか
 */
export const isDev = () => {
    // 現在のURLからhttp://またはhttps://のプロトコルを取得
    const protocol = window.location.protocol
    if (protocol === 'http:') {
        return true
    }

    // ドメインがlocalhost、devで始まる場合は開発環境と判定
    const domain = window.location.href.replace(`${protocol}//`, '')
    const isDevURL = domain.startsWith('dev')

    return isDevURL || process.env.NODE_ENV === 'development'
}

/**
 * 画像の圧縮
 *
 * @param   file    画像ファイル
 * @param   options 圧縮オプション
 *
 * @returns 圧縮後の画像ファイル
 */
export const compressImage = async (
    file: File,
    options: compressImageType = {
        maxSizeMB: 1,
        useWebWorker: true,
    }
): Promise<File | null> => {
    const compressedFile = await imageCompression(file, options)
        .then((res) => {
            return res as File
        })
        .catch((_) => {
            toast.error('画像の圧縮に失敗しました')
            return null
        })

    return compressedFile
}
