import { memo, useEffect, useState } from 'react'
import { CheckboxWrapper, SearchFieldWrapper } from './styles'
import { FlavorReqSchema, FlavorResSchema } from '../../utils/constants'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'
import SearchField from '../../../../components/SearchField'
import SpacerY from '../../../../components/Spacer/Y'
import useMutation from '../../../../hooks/useMutation'
import Layout from '../../../../layouts/Layout'
import { api } from '../../../../utils/constants'
import { Flavor } from '../../../../utils/types'
import Back from '../../../../components/Back'

type Props = {
    flavorIds: number[] | undefined
    flavorOptions: Flavor[]
    closePage: () => void
    push: <X = number>(obj: X) => void
    remove: <X = number>(index: number) => X | undefined
}

const SelectFlavorView = memo(function SelectFlavorView({
    flavorIds,
    flavorOptions,
    closePage,
    push,
    remove,
}: Props) {
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
    const [isOptionDisabled, setIsOptionDisabled] = useState<boolean>(false)
    const [initialState, _] = useState<number[]>(flavorIds || [])
    const [flavorPage, setFlavorPage] = useState<number>(1)
    const [filteredOptions, setFilteredOptions] = useState<Flavor[]>([])
    const { postAction } = useMutation<FlavorReqSchema, FlavorResSchema>({
        apiURL: api.flavors,
    })

    // 初回レンダリング時にフレーバーをセットする
    useEffect(() => {
        setFilteredOptions(flavorOptions)
    }, [])

    // フレーバーの選択状態でチェックボックス・ボタンの有効/無効を切り替える
    useEffect(() => {
        if (!flavorIds) return
        setIsOptionDisabled(flavorIds.length >= 3)

        // 初期状態と選択状態を比較
        if (initialState.length !== flavorIds.length) {
            setIsButtonDisabled(false)
            return
        }
        // 選択状態が初期状態と異なるかどうか
        const isDifferent = flavorIds.some((id) => !initialState.includes(id))

        setIsButtonDisabled(!isDifferent)
    }, [flavorIds])

    // フレーバーを追加取得する
    const handleLoadMore = async () => {
        setFlavorPage(flavorPage + 1)
        const res = await postAction({ page: flavorPage })
        if (res.error) return
        if (!res.data) return
        const flavors = res.data.resources.flavors
        flavorOptions.push(...flavors)
        setFilteredOptions((prev) => [...prev, ...flavors])
    }

    // フレーバーを検索する
    const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (value === '') {
            setFilteredOptions(flavorOptions)
            return
        }
        const filtered = flavorOptions.filter((flavor) =>
            flavor.name.includes(value)
        )
        setFilteredOptions(filtered)
    }

    // フレーバーを選択または解除する
    const handleSelectFlavor = (id: number) => {
        flavorIds?.includes(id) ? remove(flavorIds.indexOf(id)) : push(id)
    }

    // 選択フレーバーを更新する
    const handleSubmit = () => {
        closePage()
    }

    // ページを閉じる
    const handleCancel = () => {
        // フレーバー選択をリセットする
        if (flavorIds) {
            for (let i = 0; i < flavorIds.length; i++) {
                remove(0)
            }
            initialState.forEach((id) => push(id))
        }

        closePage()
    }

    return (
        <Layout
            headerTitle="フレーバーを追加する"
            headerLeftEle={
                <Back
                    width={65}
                    height={25}
                    text={'キャンセル'}
                    type="button"
                    onClick={handleCancel}
                />
            }
            headerRightEle={
                <Button
                    type="button"
                    width={66}
                    height={25}
                    paddingY={4}
                    paddingX={8}
                    fontSize={10}
                    fontWeight={700}
                    disabled={isButtonDisabled}
                    onClick={handleSubmit}
                >
                    {'追加する'}
                </Button>
            }
            isVisibleBottomNav={false}
        >
            <SpacerY height={4} />
            <SearchFieldWrapper>
                <SearchField
                    name="search"
                    placeholder="フレーバーを検索する"
                    onChange={handleFilter}
                />
            </SearchFieldWrapper>
            <SpacerY height={12} />
            {filteredOptions.map((flavor: Flavor, index: number) => (
                <CheckboxWrapper key={index}>
                    <Checkbox
                        name={`flavor[${index}]`}
                        label={flavor.name}
                        disabled={
                            isOptionDisabled && !flavorIds?.includes(flavor.id)
                        }
                        checked={flavorIds?.includes(flavor.id)}
                        onChange={() => handleSelectFlavor(flavor.id)}
                        right={28}
                    />
                </CheckboxWrapper>
            ))}
        </Layout>
    )
})

export default SelectFlavorView
