import { memo, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Image, Wrapper } from './styles'
import { ResSchema } from './utils/constants'
import useQuery from '../../hooks/useQuery'
import Modal from '../../components/Modal'

const ImagePreview = memo(function ImagePreview() {
    const { image_id } = useParams()
    const navigate = useNavigate()
    const [imagePath, setImagePath] = useState<string | null>(null)
    const { data, error } = useQuery<ResSchema>({
        apiURL: `/api/image/${image_id}`,
    })

    useEffect(() => {
        if (error) return
        if (!data) return

        setImagePath(data.resources.image_path)
    }, [])

    if (!imagePath) return null

    return (
        <Wrapper>
            <Modal isOpen={true} closeModal={() => navigate(-1)}>
                <Image src={imagePath} />
            </Modal>
        </Wrapper>
    )
})

export default ImagePreview
