import styled from 'styled-components'
import { colors } from '../../../../_principles'

export const FieldWrapper = styled.div`
    min-width: 223px;

    & textarea {
        overflow: hidden;
        resize: none;
        box-sizing: border-box;
        line-height: 24px;
        outline: none;
        color: ${colors.characterTitle85};
    }
`

export const RightIcon = styled.img`
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translate(0, -50%);
`
