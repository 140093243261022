import styled from 'styled-components'
import { colors } from '../../_principles'

type InputProps = {
    $width?: number
    $height?: number
    $fontSize?: number
    $color?: string
    $backgroundColor?: string
    $borderColor?: string
    $borderRadius?: number
} & React.InputHTMLAttributes<HTMLInputElement>

export const Input = styled.input<InputProps>`
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    height: ${({ $height }) => $height ?? 30}px;
    padding: 4px 8px;
    border: 1px solid
        ${({ $borderColor }) => $borderColor ?? colors.gray.neutral6};
    border-radius: ${({ $borderRadius }) => $borderRadius ?? 100}px;
    box-sizing: border-box;
    font-size: ${({ $fontSize }) => $fontSize ?? 12}px;
    background-color: ${({ $backgroundColor }) =>
        $backgroundColor ?? colors.gray.neutral4};
    color: ${({ color }) => color ?? colors.black};
    &:focus {
        outline: none;
    }
`
