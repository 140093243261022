import * as Yup from 'yup'
import { Flavor, InputType, Post } from '../../../utils/types'

export const validationSchema = Yup.object().shape({
    ['title']: Yup.string()
        .required('タイトルを入力してください')
        .min(1, '1文字以上で入力してください')
        .max(15, '15文字以内で入力してください'),
    ['content']: Yup.string()
        .required('本文を入力してください')
        .min(1, '1文字以上で入力してください')
        .max(500, '500文字以内で入力してください'),
    ['images']: Yup.array().of(
        Yup.mixed<File>().required('写真を選択してください')
    ),
    ['flavor_ids']: Yup.array().of(
        Yup.number().required('フレーバーを選択してください')
    ),
})

export interface PostForm extends Yup.InferType<typeof validationSchema> {}

type FieldType = {
    type: InputType
    name: keyof PostForm
    label: string
    placeholder?: string
    fontSize?: number
    autoFocus?: boolean
    options?: { value: string; label: string }[]
    multiple?: boolean
}

export const fields: FieldType[] = [
    {
        type: 'text',
        name: 'title',
        label: 'タイトル',
        placeholder: 'テキストを入力',
        fontSize: 16,
        autoFocus: true,
    },
    {
        type: 'textarea',
        name: 'content',
        label: '本文',
        placeholder: 'テキストを入力',
        fontSize: 16,
    },
    {
        type: 'select',
        name: 'flavor_ids',
        label: 'フレーバー選択',
    },
    {
        type: 'file',
        name: 'images',
        label: '写真選択',
        multiple: true,
    },
]

export type FlavorReqSchema = {
    page?: number
}

export type FlavorResSchema = {
    flavors: Flavor[]
}

export type DetailResSchema = {
    post: Post
}

export type CreateReqSchema = PostForm

export type CreateResSchema = {}

export type UpdateReqSchema = {
    ['post_id']: number
} & PostForm

export type UpdateResSchema = {}
