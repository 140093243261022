import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

export const Image = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
`
