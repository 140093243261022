import React, { memo } from 'react'
import { Wrapper } from './styles'
import { ButtonType } from '../../utils/types'

export type Props = {
    width?: number
    height?: number
    paddingY?: number
    paddingX?: number
    btnType?: ButtonType
    borderRadius?: number
    fontSize?: number
    fontWeight?: number
    lineHeight?: number
    isOutline?: boolean
    icon?: React.ReactElement
} & React.ComponentPropsWithoutRef<'button'>

const Button = memo(function Button(props: Props) {
    const {
        children,
        width = 32,
        height = 32,
        paddingY = 0,
        paddingX = 15,
        disabled,
        btnType = 'primary',
        borderRadius = 400,
        fontSize = 14,
        fontWeight = 500,
        lineHeight = 22,
        isOutline = false,
        icon,
        ...otherProps
    } = props

    return (
        <Wrapper
            {...otherProps}
            height={height}
            disabled={disabled}
            $width={width}
            $paddingY={paddingY}
            $paddingX={paddingX}
            $btnType={btnType}
            $borderRadius={borderRadius}
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $lineHeight={lineHeight}
            $isOutline={isOutline}
            $isOnlyIcon={!children}
        >
            {icon && icon}
            {children}
        </Wrapper>
    )
})

export default Button
