import styled from 'styled-components'
import { colors } from '../../_principles'

type ActionButtonProps = {
    $isDelete?: boolean
}

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid ${colors.gray.neutral1};
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;

    & > :first-child {
        border-bottom: 1px solid ${colors.gray.neutral1};
    }

    & > :last-child {
        border-top: 1px solid ${colors.gray.neutral1};
    }
`

export const ActionButton = styled.button<ActionButtonProps>`
    padding: 8px 24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${({ $isDelete }) =>
        $isDelete ? colors.red.error : colors.gray.neutral1};
`
