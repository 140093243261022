import styled from 'styled-components'

type TextProps = {
    $fontSize: number
    $fontWeight: number
    $lineHeight: number
    $letterSpacing: number
    $color: string
    $textAlign: string
}

export const Wrapper = styled.span<TextProps>`
    font-size: ${({ $fontSize }) => $fontSize}px;
    font-weight: ${({ $fontWeight }) => $fontWeight};
    line-height: ${({ $lineHeight }) => $lineHeight};
    letter-spacing: ${({ $letterSpacing }) => $letterSpacing}px;
    color: ${({ $color }) => $color};
    text-align: ${({ $textAlign }) => $textAlign};
    white-space: pre-wrap;
    word-wrap: break-word;
`
