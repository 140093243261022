import { memo } from 'react'
import { Form, FormikProps } from 'formik'
import { toast } from 'react-toastify'
import { IconWrapper } from './styles'
import { FieldItem, UserForm, fields } from '../../utils/constants'
import { CoverWrapper, AvatarWrapper } from '../../../styles'
import {
    acceptImageType,
    imagePathRegex,
    maxImageSize,
} from '../../../../../utils/constants'
import CoverImage from '../../../../../components/CoverImage'
import SelectFile from '../../../../../components/SelectFile'
import Icon from '../../../../../components/Icon'
import { assets } from '../../../../../utils'
import Avatar from '../../../../../components/Avatar'
import { User } from '../../../../../utils/types'
import Thumb from '../../../../../components/Thumb'
import { compressImage } from '../../../../../utils/functions'

type Props = {
    user: User
    setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
} & FormikProps<UserForm>

const ImageForm = memo(function ImageForm({
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    user,
    setIsDisabled,
}: Props) {
    // ファイルをセット
    const handleSetFile = async (file: File, field: FieldItem) => {
        const compressedImage = await compressImage(file)
        if (!compressedImage) return

        const isValid = imagePathRegex.test(compressedImage.name)
        if (!isValid) {
            toast.error('画像名に使用できない文字が含まれています')
            return
        }
        // 画像ファイルのサイズが1MB以下かチェック
        if (compressedImage.size > maxImageSize) {
            toast.error('画像のサイズが1MBを超えています')
            return
        }

        setFieldValue(field.name, compressedImage)
        setIsDisabled(false)
    }

    return (
        <Form>
            <CoverWrapper>
                {values[fields[2].name] ? (
                    <Thumb image={values[fields[2].name]!} height={125} />
                ) : (
                    <CoverImage canEdit src={user.cover_image} />
                )}
                <IconWrapper $top={35} $left={45}>
                    <SelectFile
                        icon={
                            <Icon
                                src={assets.addPhoto}
                                width={32}
                                height={32}
                            />
                        }
                        accept={acceptImageType}
                        setFile={(f) => handleSetFile(f, fields[2])}
                    />
                </IconWrapper>
            </CoverWrapper>
            <AvatarWrapper>
                {values[fields[1].name] ? (
                    <Thumb
                        image={values[fields[1].name]!}
                        width={67}
                        height={67}
                    />
                ) : (
                    <Avatar canEdit src={user.avatar} />
                )}
                <IconWrapper $top={20} $left={28}>
                    <SelectFile
                        icon={
                            <Icon
                                src={assets.addPhoto}
                                width={32}
                                height={32}
                            />
                        }
                        setFile={(f) => handleSetFile(f, fields[1])}
                    />
                </IconWrapper>
            </AvatarWrapper>
        </Form>
    )
})

export default ImageForm
