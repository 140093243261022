import SpacerY from '../../../../../components/Spacer/Y'
import { Container } from './styles'

type Props = {
    children: React.ReactNode
}

export const FormWrapper = ({ children }: Props) => {
    return (
        <Container>
            <SpacerY height={85} />
            {children}
        </Container>
    )
}
