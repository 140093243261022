import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Container, LogoWrapper } from './styles'
import Button from '../../../components/Button'
import Icon from '../../../components/Icon'
import Logo from '../../../components/Logo'
import SpacerY from '../../../components/Spacer/Y'
import useMutation from '../../../hooks/useMutation'
import Layout from '../../../layouts/Layout'
import { assets, storage } from '../../../utils'
import { api } from '../../../utils/constants'
import { isDev, isTest } from '../../../utils/functions'
import { User } from '../../../utils/types'

type schema = {
    url: string
    user?: User
}

export default function Top() {
    const navigate = useNavigate()
    const [isLocal, setIsLocal] = useState<boolean>(false)
    const { postAction } = useMutation<{}, schema>({ apiURL: api.login })
    const { postAction: DummyLogin } = useMutation<{}, schema>({
        apiURL: api.dummyLogin,
    })

    useEffect(() => {
        setIsLocal(isDev() || isTest())
    }, [])

    // Googleログイン処理
    const handleSubmit = async () => {
        const res = await postAction()
        if (res.error) return
        const url = res.data?.resources.url
        if (!url) {
            toast.error('Googleログインに失敗しました')
            window.location.reload()
            return
        }
        window.location.href = url
    }

    // ダミーユーザーでのログイン処理(ローカル環境のみ)
    const handleSubmitDummy = async () => {
        const res = await DummyLogin()
        if (res.error) return
        if (!res.data) return
        const url = res.data.resources.url
        const user = res.data.resources.user
        if (!url) {
            toast.error('ログインに失敗しました')
            window.location.reload()
            return
        }
        if (!user) {
            toast.error('ログインに失敗しました')
            window.location.reload()
            return
        }
        storage.setUser(user)
        navigate(url)
    }

    return (
        <Layout isVisibleHeader={false} isVisibleBottomNav={false}>
            <Container>
                <SpacerY height={259} />
                <LogoWrapper>
                    <Logo src={assets.logo} alt="Mokusy Logo" />
                </LogoWrapper>
                <SpacerY height={96} />
                <Button
                    type="submit"
                    height={40}
                    borderRadius={62}
                    width={311}
                    fontWeight={700}
                    icon={
                        <Icon
                            src={assets.googleIcon}
                            alt="Mokusy Google Icon"
                            width={20}
                            height={20}
                        />
                    }
                    onClick={handleSubmit}
                >
                    Continue with Google
                </Button>
                {/* ローカル環境のみ、ダミーユーザーでのログインを許可 */}
                {isLocal && (
                    <>
                        <SpacerY height={16} />
                        <Button
                            type="submit"
                            height={40}
                            btnType="neutral"
                            borderRadius={62}
                            width={311}
                            fontWeight={700}
                            onClick={handleSubmitDummy}
                        >
                            (DEV) Continue with Dummy
                        </Button>
                    </>
                )}
            </Container>
        </Layout>
    )
}
