import styled from 'styled-components'
import { colors } from '../../_principles'

export const LayoutWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    height: 100vh;
    background-color: ${colors.white};
    position: relative;
`

export const Main = styled.main`
    flex: 1;
    overflow-y: auto;
`

export const FloatingWrapper = styled.div`
    position: absolute;
    bottom: 80px;
    right: 17px;
    z-index: 100;
`

export const BottomEleWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 70px;
    background-color: ${colors.white};
`
