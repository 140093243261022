import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useQuery from '../../../hooks/useQuery'
import Layout from '../../../layouts/Layout'
import { User } from '../../../utils/types'
import { storage } from '../../../utils'
import { api, url } from '../../../utils/constants'

type ResSchema = {
    user: User
}

export default function LoginCallback() {
    const navigate = useNavigate()
    const { data, loading, error } = useQuery<ResSchema>({
        apiURL: api.loginCallback,
    })

    useEffect(() => {
        if (!data) return
        if (error) return
        storage.setUser(data.resources.user)
        navigate(url.home)
    }, [data])

    return (
        <Layout isVisibleHeader={false} isVisibleBottomNav={false}>
            {loading && 'Loading...'}
        </Layout>
    )
}
