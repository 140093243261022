import styled, { css } from 'styled-components'
import { Props } from '.'
import { ButtonType } from '../../utils/types'
import { colors } from '../../_principles'

type ButtonProps = Pick<Props, 'height'> & {
    $borderRadius: number
    $btnType: ButtonType
    $fontSize: number
    $fontWeight: number
    $lineHeight: number
    $isOutline?: boolean
    $isOnlyIcon?: boolean
    $width?: number
    $paddingY: number
    $paddingX: number
}

const backgroundColors: Record<ButtonType, string> = {
    primary: colors.green.primary,
    secondary: colors.gray.neutral4,
    linear: `linear-gradient(97deg, ${colors.green.fadedPoster} 11.06%, ${colors.blue.blessedBlue} 98.02%)`,
    transparent: 'transparent',
    error: colors.red.error,
    success: colors.blue.blessedBlue,
    neutral: colors.gray.neutral3,
}

const textColors: Record<ButtonType, string> = {
    primary: colors.white,
    secondary: colors.characterTitle85,
    linear: colors.white,
    transparent: colors.characterTitle85,
    error: colors.white,
    success: colors.white,
    neutral: colors.black,
}

const textColorsOutline: Record<ButtonType, string> = {
    primary: colors.green.primary,
    secondary: colors.characterTitle85,
    linear: colors.green.fadedPoster,
    transparent: colors.characterTitle85,
    error: colors.red.error,
    success: colors.blue.blessedBlue,
    neutral: colors.black,
}

const borderColors: Record<ButtonType, string> = {
    primary: colors.green.primary,
    secondary: colors.gray.neutral4,
    linear: colors.green.fadedPoster,
    transparent: 'transparent',
    error: colors.gray.neutral6,
    success: colors.gray.neutral6,
    neutral: colors.gray.neutral6,
}

const borderColorsOutline: Record<ButtonType, string> = {
    primary: colors.green.primary,
    secondary: colors.gray.neutral4,
    linear: colors.green.fadedPoster,
    transparent: 'transparent',
    error: colors.red.error,
    success: colors.blue.blessedBlue,
    neutral: colors.gray.neutral6,
}

export const Wrapper = styled.button<ButtonProps>`
    width: ${({ $width }) => `${$width}px` || 'max-content'};
    height: ${({ height }) => `${height}px`};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: ${({ $paddingY, $paddingX }) => `${$paddingY}px ${$paddingX}px`};
    font-size: ${({ $fontSize }) => `${$fontSize}px`};
    font-weight: ${({ $fontWeight }) => $fontWeight || 500};
    line-height: ${({ $lineHeight }) => `${$lineHeight}px`};
    cursor: pointer;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    border-radius: ${({ $borderRadius }) => `${$borderRadius}px`};

    ${({ $isOnlyIcon }) => {
        if ($isOnlyIcon) {
            return css`
                background: transparent;
                border: none;
            `
        }

        return css<ButtonProps>`
            background: ${({ $btnType, $isOutline }) =>
                $isOutline ? colors.white : backgroundColors[$btnType]};
            color: ${({ $btnType, $isOutline }) =>
                $isOutline
                    ? textColorsOutline[$btnType]
                    : textColors[$btnType]};
            border: 1px solid
                ${({ $btnType, $isOutline }) =>
                    $isOutline
                        ? borderColorsOutline[$btnType]
                        : borderColors[$btnType]};
        `
    }}

    &:disabled {
        cursor: default;
        border: 1px solid ${colors.green.primaryDisabled};
        background: ${colors.green.primaryDisabled};
        color: ${colors.white};
    }
`
