import styled from 'styled-components'
import { colors } from '../../_principles'

type HeaderWrapperProps = {
    $isBorderBottom?: boolean
}

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
    height: 40px;
    ${({ $isBorderBottom }) =>
        $isBorderBottom &&
        `border-bottom: 1px solid ${colors.green.primaryDisabled};`};
`

export const ElementWrapper = styled.div<{
    $isLeft?: boolean
    $isRight?: boolean
}>`
    display: flex;
    align-items: center;
    gap: 8px;
`
