import { useEffect, useMemo, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import FormView from '../components/Form'
import FormLayout from '../components/Layout'
import {
    PostForm,
    DetailResSchema,
    UpdateReqSchema,
    UpdateResSchema,
    validationSchema,
} from '../utils/constants'
import useMutation from '../../../hooks/useMutation'
import useQuery from '../../../hooks/useQuery'
import { storage } from '../../../utils'
import { api, url } from '../../../utils/constants'

export default function PostEdit() {
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [initialValues, setInitialValues] = useState<PostForm | null>(null)
    const formikRef = useRef<FormikProps<PostForm> | null>(null)
    const user = storage.getUser()
    const { post_id, user_id } = useParams()
    const navigate = useNavigate()
    const { data, error } = useQuery<DetailResSchema>({
        apiURL: `/api/post/${user_id}/${post_id}`,
    })
    const { postAction } = useMutation<UpdateReqSchema, UpdateResSchema>({
        apiURL: `${api.post}/update`,
    })

    useEffect(() => {
        if (!user) {
            toast.error('ログインしてください')
            navigate(url.top)
            return
        }

        // fix route to 404
        if (!post_id || !user_id) {
            toast.error('投稿が見つかりません')
            navigate(`/${user.user_id}`)
            return
        }

        if (isNaN(Number(post_id))) {
            toast.error('投稿が見つかりません')
            navigate(`/${user.user_id}`)
            return
        }

        if (user_id !== user?.user_id) {
            toast.error('編集権限がありません')
            navigate(`/${user.user_id}`)
            return
        }
    }, [])

    const { post } = useMemo(() => {
        if (error) return { post: null }
        if (!data) return { post: null }
        return { post: data.resources.post }
    }, [data])

    useEffect(() => {
        if (!post) return

        const initialValues: PostForm = {
            ['title']: post.title,
            ['content']: post.content,
            ['images']: [],
            ['flavor_ids']: post.flavors.map((flavor) => flavor.id),
        }
        setInitialValues(initialValues)
    }, [post])

    const handleSubmit = async (values: PostForm) => {
        if (!user) return null
        if (!post_id) return null
        if (!post) return null

        const params = new FormData()
        params.append('post_id', post_id)
        params.append('title', values.title)
        params.append('content', values.content)
        values.images?.forEach((image, index) =>
            params.append(`images[${index}]`, image, image.name)
        )
        values.flavor_ids?.forEach((flavor_id, index) =>
            params.append(`flavor_ids[${index}]`, flavor_id.toString())
        )

        const res = await postAction(params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        if (res.error) return
        if (!res.data) return
        toast.success(res.data.message)
        navigate(`/${user.user_id}`)
    }

    return (
        <FormLayout isEdit isDisabled={isDisabled} formikRef={formikRef}>
            {initialValues && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formikRef}
                    onSubmit={handleSubmit}
                    // validate={(values) => handleChange({ values, setIsDisabled })}
                >
                    {(props) => (
                        <FormView
                            imageCount={post?.images.length}
                            setIsDisabled={setIsDisabled}
                            {...props}
                        />
                    )}
                </Formik>
            )}
        </FormLayout>
    )
}
