import { PostForm } from '../pages/Post/utils/constants'
import { Flavor, User } from './types'

export const getUser = (): User | null => {
    const user = localStorage.getItem('user')
    return user ? (JSON.parse(user) as User) : null
}

export const setUser = (user: User) => {
    const stringifyUser = JSON.stringify(user)
    localStorage.setItem('user', stringifyUser)
}

export const getToken = () => {
    const user = localStorage.getItem('token')
    return user
}

export const setToken = (token: string) => {
    localStorage.setItem('token', token)
}

export const clearStorage = () => {
    localStorage.clear()
}
