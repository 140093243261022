import { useEffect, useMemo, useRef, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AvatarWrapper, CommentFormWrapper, Wrapper } from './styles'
import {
    DeleteCommentReqSchema,
    DeletCommentResSchema,
    DeletePostReqSchema,
    DeletePostResSchema,
} from './utils/constants'
import FormView from '../components/CommentForm'
import {
    CreateReqSchema,
    CreateResSchema,
    CommentForm,
    validationSchema,
} from '../../Comment/utils/constants'
import Avatar from '../../../components/Avatar'
import Back from '../../../components/Back'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import useMutation from '../../../hooks/useMutation'
import useQuery from '../../../hooks/useQuery'
import Layout from '../../../layouts/Layout'
import { storage } from '../../../utils'
import { api } from '../../../utils/constants'
import { Comment, Post } from '../../../utils/types'

type ResSchema = {
    post: Post
}

export default function PostDetail() {
    const user = storage.getUser()
    const { post_id, user_id } = useParams()
    const navigate = useNavigate()
    const formikRef = useRef<FormikProps<CommentForm> | null>(null)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isFocus, setIsFocus] = useState(false)
    const [post, setPost] = useState<Post | null>(null)
    const [comments, setComments] = useState<Comment[]>([])
    const [initialValues, setInitialValues] = useState<CommentForm | null>(null)
    const { data, error } = useQuery<ResSchema>({
        apiURL: `/api/post/${user_id}/${post_id}`,
    })
    const { postAction: deletePostAction } = useMutation<
        DeletePostReqSchema,
        DeletePostResSchema
    >({
        apiURL: `${api.post}/delete`,
    })
    const { postAction: deleteCommentAction } = useMutation<
        DeleteCommentReqSchema,
        DeletCommentResSchema
    >({
        apiURL: `${api.comment}/delete`,
    })
    const { postAction: postComment } = useMutation<
        CreateReqSchema,
        CreateResSchema
    >({
        apiURL: api.comment,
    })

    useEffect(() => {
        if (!user) return

        // fix route to 404
        if (!user_id || !post_id) {
            toast.error('投稿が見つかりません')
            navigate(`/${user.user_id}`)
            return
        }

        if (isNaN(Number(post_id))) {
            toast.error('投稿が見つかりません')
            navigate(`/${user.user_id}`)
            return
        }

        setInitialValues({
            ['content']: '',
        })
    }, [])

    useEffect(() => {
        if (error) {
            navigate(`/${user?.user_id}`)
        }
    }, [error])

    useMemo(() => {
        if (!data) return
        setPost(data.resources.post)
        setComments(data.resources.post.comments)
    }, [data])

    const renderCommentForm = () => {
        if (!user) return null
        if (!post_id) return null
        if (!initialValues) return null

        return (
            <CommentFormWrapper>
                <AvatarWrapper>
                    <Avatar src={user.avatar} width={32} height={32} />
                </AvatarWrapper>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    innerRef={formikRef}
                    onSubmit={handleSubmitComment}
                >
                    {(props) => (
                        <FormView
                            user={user}
                            postID={post_id}
                            isFocus={isFocus}
                            setIsFocus={setIsFocus}
                            setIsDisabled={setIsDisabled}
                            {...props}
                        />
                    )}
                </Formik>
                {isFocus && (
                    <Button
                        type="submit"
                        width={56}
                        height={30}
                        paddingX={0}
                        fontSize={10}
                        fontWeight={700}
                        disabled={isDisabled}
                        onClick={() => formikRef.current?.submitForm()}
                    >
                        {'投稿する'}
                    </Button>
                )}
            </CommentFormWrapper>
        )
    }

    const handleSubmitComment = async (values: CommentForm) => {
        if (!user) return null
        if (!post_id) return null

        const params = new FormData()
        params.append('post_id', post_id)
        params.append('content', values.content)

        const res = await postComment(params)
        if (res.error) return
        if (!res.data) return

        setComments([...comments, res.data.resources.comment])
        formikRef.current?.resetForm()
        toast.success(res.data.message)
    }

    // 投稿編集画面へ遷移
    const handlePostEdit = (id: number) => {
        if (!user) return
        navigate(`/${user.user_id}/post/${id}/edit`)
    }

    // 投稿削除処理
    const handlePostDelete = async (id: number) => {
        if (!user) return
        const params = {
            ['post_id']: id,
        }
        const res = await deletePostAction(params)
        if (res.error) return
        if (!res.data) return

        toast.success(res.data.message)
        navigate(`/${user.user_id}`)
    }

    // コメント編集画面へ遷移
    const handleCommentEdit = (id: number) => {
        if (!user) return
        if (!post) return
        navigate(`/${user.user_id}/post/${post.id}/comment/${id}/edit`)
    }

    // コメント削除処理
    const handleCommentDelete = async (id: number) => {
        const params = {
            ['comment_id']: id,
        }
        const res = await deleteCommentAction(params)
        if (res.error) return
        if (!res.data) return

        toast.success(res.data.message)
        setComments(comments.filter((comment) => comment.id !== id))
    }

    return (
        <Layout
            headerTitle={`投稿`}
            headerLeftEle={
                <Back
                    width={10}
                    height={20}
                    withIcon
                    onClick={() => navigate(`/${user?.user_id}`)}
                />
            }
            bottomEle={renderCommentForm}
        >
            <Wrapper>
                {post && (
                    <>
                        <Card
                            id={post.id}
                            title={post.title}
                            content={post.content}
                            flavors={post.flavors}
                            images={post.images}
                            postedAt={post.posted_at}
                            commentCount={post.comments.length}
                            user={post.user}
                            onEdit={() => handlePostEdit(post.id)}
                            onDelete={() => handlePostDelete(post.id)}
                        />
                        {comments.map((comment, index) => (
                            <Card
                                key={index}
                                id={comment.id}
                                title={comment.user.user_id}
                                content={comment.content}
                                postedAt={comment.posted_at}
                                user={comment.user}
                                onEdit={() => handleCommentEdit(comment.id)}
                                onDelete={() => handleCommentDelete(comment.id)}
                            />
                        ))}
                    </>
                )}
            </Wrapper>
        </Layout>
    )
}
