import * as Yup from 'yup'
import { userIDRegex } from '../../../../../utils/constants'
import { User } from '../../../../../utils/types'

export const validationSchema = Yup.object().shape({
    ['user_id']: Yup.string()
        .min(5, '5文字以上で入力してください')
        .max(15, '15文字以内で入力してください')
        .required('ユーザーIDを入力してください')
        .matches(userIDRegex, '半角英数字とアンダースコア(_)のみ使用できます'),
})

export interface Form extends Yup.InferType<typeof validationSchema> {}

export const initialValues: Form = {
    ['user_id']: '',
}

export type ResSchema = {
    url: string
    user: User
}

export type checkExistsReqSchema = {}

export type checkExistsResSchema = {
    is_exists: boolean
}
