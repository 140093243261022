import styled from 'styled-components'

export const FieldWrapper = styled.div`
    position: relative;
`

export const CountdownWrapper = styled.div`
    position: absolute;
    bottom: 0;
    right: 8px;
`
